<template>
  <v-row>
    <v-col cols="12" sm="6" md="4" xl="2">
      <v-card class="pa-6 d-flex align-center" style="gap: 16px" outlined>
        <div>
          <div class="card-icon border pa-4">
            <div class="primary">
              <i class="ri-exchange-dollar-line ri-lg white--text"></i>
            </div>
          </div>
        </div>
        <div class="flex foreground--text">
          <p class="mb-2">Conversion Rate</p>
          <h2>{{ conversionRate }}%</h2>
        </div>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6" md="4" xl="2">
      <v-card class="pa-6 d-flex align-center" style="gap: 16px" outlined>
        <div>
          <div class="card-icon border pa-4">
            <div class="primary">
              <i class="ri-file-list-3-line ri-lg white--text"></i>
            </div>
          </div>
        </div>
        <div class="flex foreground--text">
          <p class="mb-2">Visitors</p>
          <h2>{{ visitors }}</h2>
        </div>
      </v-card>
    </v-col>
    <v-col v-for="(item, index) in chartDataZipped" :key="index" cols="12" sm="6" md="4" xl="2">
      <v-card class="pa-6 d-flex align-center" style="gap: 16px" outlined>
        <div>
          <div class="card-icon border pa-4">
            <div class="primary">
              <i :class="`${item.icon} ri-lg white--text`"></i>
            </div>
          </div>
        </div>
        <div class="flex foreground--text">
          <p class="mb-2 text-capitalize">{{ item.label === 'view product' ? 'Page Views' : item.label }}</p>
          <h2>{{ item.value }}</h2>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { FETCH_CONVERSION_RATE } from '@/store/actions.type';
import STORAGE_NAME from '@/const/storage';

export default {
  props: {
    dateRange: {
      from: Date,
      to: Date,
    },
    stores: Array,
    timezone: String,
  },
  data() {
    return {
      isLoading: false,
      data: null,
      chartDataZipped: [],
    };
  },
  async created() {
    this.isLoading = true;
    let { startDate, endDate, stores } = this.loadQueryFromCache();
    let fromTime = startDate
      ? startDate
      : this.$props.dateRange && this.$props.dateRange.from
      ? moment(this.$props.dateRange.from).format('YYYY-MM-DD')
      : moment()
          .tz(this.timezone)
          .format('YYYY-MM-DD');
    let toTime = endDate
      ? endDate
      : this.$props.dateRange && this.$props.dateRange.to
      ? moment(this.$props.dateRange.to).format('YYYY-MM-DD')
      : moment()
          .tz(this.timezone)
          .format('YYYY-MM-DD');
    let _stores = stores ? stores : this.$props.stores ? this.$props.stores : '';
    await this.$store.dispatch(FETCH_CONVERSION_RATE, { fromTime, toTime, stores: _stores, timezone: this.timezone });
    this.zipChartData();
    this.isLoading = false;
  },
  methods: {
    loadQueryFromCache() {
      let cacheQuery = localStorage.getItem(STORAGE_NAME.REPORT_SEARCH_QUERY);
      return cacheQuery ? JSON.parse(cacheQuery) : {};
    },
    iconList(label) {
      switch (label) {
        case 'view product':
          return 'ri-group-line';
        case 'added to cart':
          return 'ri-shopping-bag-2-line';
        case 'reached checkout':
          return 'ri-currency-line';
        case 'session converted':
          return 'ri-swap-2-line';
      }
    },
    zipChartData() {
      const values = this.chartData.datasets[0].data || [];
      this.chartDataZipped = this.chartData.labels.map((label, index) => {
        return {
          label: label.toLowerCase(),
          value: values[index],
          icon: this.iconList(label.toLowerCase()),
        };
      });
    },
  },
  computed: {
    ...mapGetters(['chartData', 'chartOptions']),
    conversionRate() {
      return this.$store.getters.conversionRate;
    },
    uselConversionRate() {
      return this.$store.getters.uselConversionRate;
    },
    onlineStoreConversionRate() {
      return this.$store.getters.onlineStoreConversionRate;
    },
    visitors() {
      return this.$store.getters.visitors || 0;
    },
  },
  watch: {
    dateRange: async function(newTime, currTime) {
      // let { stores } = this.loadQueryFromCache();
      let fromTime = newTime
        ? moment(newTime.from).format('YYYY-MM-DD')
        : moment()
            .tz(this.timezone)
            .format('YYYY-MM-DD');
      let toTime =
        newTime && newTime.to
          ? moment(newTime.to).format('YYYY-MM-DD')
          : moment()
              .tz(this.timezone)
              .format('YYYY-MM-DD');
      await this.$store.dispatch(FETCH_CONVERSION_RATE, {
        fromTime,
        toTime,
        stores: this.stores,
        timezone: this.timezone,
      });

      await this.zipChartData();
    },
    stores: async function(newStores, currStores) {
      let { startDate, endDate } = this.loadQueryFromCache();
      let fromTime = startDate
        ? startDate
        : this.$props.dateRange && this.$props.dateRange.from
        ? moment(this.$props.dateRange.from).format('YYYY-MM-DD')
        : moment()
            .tz(this.timezone)
            .format('YYYY-MM-DD');
      let toTime = endDate
        ? endDate
        : this.$props.dateRange && this.$props.dateRange.to
        ? moment(this.$props.dateRange.to).format('YYYY-MM-DD')
        : moment()
            .tz(this.timezone)
            .format('YYYY-MM-DD');
      await this.$store.dispatch(FETCH_CONVERSION_RATE, {
        fromTime,
        toTime,
        stores: newStores,
        timezone: this.timezone,
      });
    },
    chartData: function(newData, currData) {
      this.zipChartData();
    },
  },
};
</script>

<style lang="scss" scoped>
.card-icon {
  border-radius: 6px;
  div {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
  }
}
</style>
