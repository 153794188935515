const menu = [
  {
    title: 'Dashboard',
    icon: 'ri-home-3-line',
    name: 'website',
    type: 'only',
    role: 'homes',
  },
  {
    title: 'Orders',
    icon: 'ri-file-list-3-line',
    name: 'website.orders',
    status: false,
    type: 'many',
    role: 'orders',
    children: [
      {
        title: 'All Orders',
        name: 'website.orders',
        role: 'orders',
      },
      // {
      //   title: 'Abandoned Checkouts',
      //   name: 'website.abandoned',
      //   role: 'orders',
      // },
    ],
  },
  {
    title: 'Products',
    icon: 'ri-t-shirt-2-line',
    name: 'website.products',
    status: false,
    type: 'many',
    role: 'products',
    children: [
      {
        title: 'All Products',
        name: 'website.products',
        role: 'products',
      },
      {
        title: 'Collections',
        name: 'website.collections',
        role: 'products',
      },
      {
        title: 'Catalogs',
        name: 'website.catalog',
        role: 'products',
      },
      {
        title: 'Inventory',
        name: 'website.inventory',
        role: 'products',
      },
      {
        title: 'Product Feeds',
        name: 'website.product-feeds',
        role: 'products',
      },
      {
        title: 'Personalized  Product',
        name: 'website.product-personalized',
        role: 'products',
      },
      {
        title: 'Bulk Updates',
        name: 'website.bulkupdates',
        role: 'products',
      },
      {
        title: 'Product Options',
        name: 'website.menufilter',
      },
      // {
      //   title: 'Reviews',
      //   name: 'website.apps.product-review.list',
      //   role: 'products',
      // },
      // {
      //   title: 'Test componet',
      //   name: 'website.products.dattest',
      // },
    ],
  },
  // {
  //   title: 'Catalog',
  //   icon: 'mdi-tag-multiple ',
  //   name: 'website.catalog',
  //   status: false,
  //   type: 'many',
  //   role: 'products',
  //   children: [
  //     {
  //       title: 'Catalog',
  //       name: 'website.catalog',
  //       role: 'products',
  //     },
  //     {
  //       title: 'Import list',
  //       name: 'website.import-list',
  //       role: 'products',
  //     },
  //   ],
  // },
  {
    title: 'Analytics',
    icon: 'ri-bar-chart-box-line',
    name: 'website.analytics',
    status: false,
    type: 'many',
    role: 'report',
    children: [
      {
        title: 'Analytics',
        name: 'website.analytics',
        role: 'dashboards',
      },
      {
        title: 'Sales Reports',
        name: 'website.sales-reports',
        role: 'sale_reports',
      },
      {
        title: 'Estimated Profit',
        name: 'website.estimated-profit',
        role: 'estimated_profit',
      },
      {
        title: 'UTM Analytics',
        name: 'website.utm-analystics',
        role: 'utm_analystics',
      },
    ],
  },
  {
    title: 'Pixel',
    icon: 'ri-pantone-line',
    status: false,
    type: 'many',
    role: 'report',
    name: 'website.pixel.sharePixel',
    children: [
      {
        title: 'Share Pixel',
        name: 'website.pixel.sharePixel',
        role: 'settings',
      },
    ],
  },
  {
    title: 'Discounts',
    icon: 'ri-discount-percent-line',
    status: false,
    type: 'many',
    role: 'discounts',
    name: 'website.discount.code',
    children: [
      {
        title: 'Code',
        name: 'website.discount.code',
        role: 'discounts',
      },
      {
        title: 'Automatic',
        name: 'website.discount.automatic',
        role: 'discounts',
      },
    ],
  },
  {
    title: 'Customer',
    icon: 'ri-group-line',
    name: 'website.customer',
    children: [],
    role: 'customers',
    type: 'only',
  },
  {
    title: 'Apps',
    icon: 'ri-function-add-line',
    name: 'website.apps',
    type: 'only',
    role: 'apps',
  },
  {
    title: 'Settings',
    icon: 'ri-settings-2-line',
    name: 'website.settings',
    children: [],
    type: 'only',
    role: 'settings',
  },
  {
    title: 'Payout',
    icon: 'ri-bank-card-line',
    name: 'website.settings.payout',
    children: [],
    type: 'only',
    role: 'settings',
  },
  {
    title: 'Online Store',
    icon: 'ri-store-2-line',
    status: false,
    type: 'many',
    role: 'store',
    children: [
      {
        title: 'Themes',
        name: 'website.themes',
        role: 'themes',
      },
      {
        title: 'Pages',
        name: 'website.pages',
        role: 'pages',
      },
      {
        title: 'Navigation',
        name: 'website.menus',
        role: 'navigations',
      },
      {
        title: 'Domain',
        name: 'website.domain',
        role: 'domains',
      },
      {
        title: 'Preferences',
        name: 'website.preferences',
        role: 'preferences',
      },
      {
        title: 'Member',
        icon: 'mdi-account-group',
        name: 'website.member',
        type: 'only',
        role: 'homes',
      },
      {
        title: 'Size chart',
        name: 'website.sizechart',
        role: 'store',
      },
    ],
  },
  // {
  //   title: 'Seller',
  //   icon: 'mdi-account-group',
  //   name: 'website.seller',
  //   children: [],
  //   type: 'only',
  //   role: 'seller',
  // },
];
const limitMenu = [
  {
    title: 'Home',
    icon: 'ri-home-3-line',
    name: 'website',
    type: 'only',
    role: 'homes',
  },
  {
    title: 'Orders',
    icon: 'ri-file-list-3-line',
    name: 'website.orders',
    status: false,
    type: 'only',
    role: 'orders',
    children: [
      // {
      //   title: 'All Orders',
      //   name: 'website.orders',
      //   role: 'orders',
      // },
      // {
      //   title: 'Abandoned Checkouts',
      //   name: 'website.abandoned',
      //   role: 'orders',
      // },
    ],
  },
  {
    title: 'Catalogs',
    icon: 'ri-box-3-line',
    name: 'website.catalog',
    status: false,
    type: 'only',
    role: 'products',
  },
  {
    title: 'Products',
    icon: 'ri-t-shirt-2-line',
    name: 'website.products',
    status: false,
    type: 'many',
    role: 'products',
    children: [
      {
        title: 'All Products',
        name: 'website.products',
        role: 'products',
      },
      {
        title: 'Collections',
        name: 'website.collections',
        role: 'products',
      },
      // {
      //   title: 'Inventory',
      //   name: 'website.inventory',
      //   role: 'products',
      // },
      // {
      //   title: 'Product Feeds',
      //   name: 'website.product-feeds',
      //   role: 'products',
      // },
      // {
      //   title: 'Personalized  Product',
      //   name: 'website.product-personalized',
      //   role: 'products',
      // },
      // {
      //   title: 'Bulk Updates',
      //   name: 'website.bulkupdates',
      //   role: 'products',
      // },
      // {
      //   title: 'Product Options',
      //   name: 'website.menufilter',
      // },
      // {
      //   title: 'Test componet',
      //   name: 'website.products.dattest',
      // },
      // {
      //   title: 'Reviews',
      //   name: 'website.apps.product-review.list',
      //   role: 'products',
      // },
    ],
  },
  // {
  //   title: 'Catalog',
  //   icon: 'mdi-tag-multiple ',
  //   name: 'website.catalog',
  //   status: false,
  //   type: 'many',
  //   role: 'products',
  //   children: [
  //     {
  //       title: 'Catalog',
  //       name: 'website.catalog',
  //       role: 'products',
  //     },
  //     {
  //       title: 'Import list',
  //       name: 'website.import-list',
  //       role: 'products',
  //     },
  //   ],
  // },

  {
    title: 'Analytics',
    icon: 'ri-bar-chart-box-line',
    name: 'website.analytics',
    status: false,
    type: 'many',
    role: 'report',
    children: [
      {
        title: 'Analytics',
        name: 'website.analytics',
        role: 'dashboards',
      },
      {
        title: 'Sales Reports',
        name: 'website.sales-reports',
        role: 'sale_reports',
      },
      {
        title: 'Estimated Profit',
        name: 'website.estimated-profit',
        role: 'estimated_profit',
      },
      {
        title: 'UTM Analytics',
        name: 'website.utm-analystics',
        role: 'utm_analystics',
      },
    ],
  },
  {
    title: 'Pixel',
    icon: 'ri-pantone-line',
    status: false,
    type: 'many',
    role: 'settings',
    name: 'website.pixel.sharePixel',
    children: [
      {
        title: 'Share Pixel',
        name: 'website.pixel.sharePixel',
        role: 'settings',
      },
    ],
  },
  // {
  //   title: 'Discounts',
  //   icon: 'mdi-sale',
  //   status: false,
  //   type: 'many',
  //   role: 'discounts',
  //   name: 'website.discount.code',
  //   children: [
  //     {
  //       title: 'Code',
  //       name: 'website.discount.code',
  //       role: 'discounts',
  //     },
  //     {
  //       title: 'Automatic',
  //       name: 'website.discount.automatic',
  //       role: 'discounts',
  //     },
  //   ],
  // },
  // {
  //   title: 'Customer',
  //   icon: 'mdi-folder-account',
  //   name: 'website.customer',
  //   children: [],
  //   role: 'customers',
  //   type: 'only',
  // },
  // {
  //   title: 'Apps',
  //   icon: 'mdi-puzzle',
  //   name: 'website.apps',
  //   type: 'only',
  //   role: 'apps',
  // },
  {
    title: 'Boost UpSell',
    icon: 'ri-funds-box-line',
    name: 'website.upsell',
    type: 'only',
    role: 'apps',
  },
  {
    title: 'Settings',
    icon: 'ri-settings-2-line',
    name: 'website.settings',
    children: [],
    type: 'only',
    role: 'settings',
  },
  {
    title: 'Payout',
    icon: 'ri-bank-card-line',
    name: 'website.settings.payout',
    children: [],
    type: 'only',
    role: 'settings',
  },
  {
    title: 'Telegram',
    icon: 'ri-telegram-line',
    name: 'website.notification',
    type: 'only',
    role: 'settings',
  },
  {
    title: 'Online Store',
    icon: 'ri-store-2-line',
    status: false,
    type: 'many',
    role: 'store',
    children: [
      // {
      //   title: 'Themes',
      //   name: 'website.themes',
      //   role: 'themes',
      // },
      {
        title: 'Pages',
        name: 'website.pages',
        role: 'pages',
      },
      {
        title: 'Navigation',
        name: 'website.menus',
        role: 'navigations',
      },
      {
        title: 'Domain',
        name: 'website.domain',
        role: 'domains',
      },
      {
        title: 'Preferences',
        name: 'website.preferences',
        role: 'preferences',
      },
      // {
      //   title: 'Member',
      //   icon: 'mdi-account-group',
      //   name: 'website.member',
      //   type: 'only',
      //   role: 'homes',
      // },
      // {
      //   title: 'Size chart',
      //   name: 'website.sizechart',
      //   role: 'store',
      // },
      // {
      //   title: 'DNS & Cache',
      //   name: 'website.dns',
      //   role: 'store',
      // },
    ],
  },
];
export default {
  menu,
  limitMenu,
};
