<script>
import { Icon } from '@iconify/vue2';
export default {
  components: {
    Icon,
  },
  computed: {
    currentSubscription() {
      return this.$store.state.subscription.currentSubscription?.name || '';
    },
    nextSubscription() {
      return this.$store.state.subscription.nextSubscription;
    },
    currentRevenue() {
      const revenue = parseFloat(this.$store.state.subscription.store60daysRevenue) || 0;
      const nextSubscriptionRevenue = this.$store.state.subscription.nextSubscription?.rankRevenue || 0;
      const percent = (revenue / nextSubscriptionRevenue) * 100;
      return percent > 100 ? 100 : percent;
    },
  },
};
</script>
<template>
  <v-row>
    <v-col cols="12" :md="!nextSubscription ? 12 : 6">
      <v-card color="primary" class="pa-6 d-flex align-center" style="height: 148px; gap: 20px">
        <div class="" style="flex-shrink: 0;">
          <Icon icon="hugeicons:ranking" :width="48" :height="48" class="warning--text" />
        </div>
        <div class="flex">
          <p class="white--text mb-0">Current Rank</p>
          <h2 class="white--text">{{ currentSubscription }}</h2>
        </div>
      </v-card>
    </v-col>
    <v-col v-if="nextSubscription != null" cols="12" md="6">
      <v-card class="d-flex align-center pa-6" style="gap: 16px; height: 100%;">
        <v-progress-circular
          :rotate="270"
          :size="100"
          :width="12"
          :value="currentRevenue"
          :color="currentRevenue === 100 ? 'success' : 'primary'"
          class="font-weight-bold fz-20"
        >
          <b>{{ Math.round(currentRevenue) }}%</b>
        </v-progress-circular>
        <div class="flex">
          <p class="mb-0">Next Rank</p>
          <h2 :class="currentRevenue === 100 ? 'success--text' : 'primary--text'">{{ nextSubscription.name }}</h2>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>
