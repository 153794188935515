<template>
  <v-card class="pa-0 d-flex flex-column" height="100%" outlined>
    <div class="py-3 px-4">
      <h3>Sales Overtime</h3>
    </div>
    <v-divider />
    <div class="pa-4 d-flex flex-column" style="flex: 1; align-items: stretch;">
      <BarChart :chartdata="totalSalesReport" :options="chartSalesOpts" style="flex: 1;" />
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import BarChart from '@/components/chart/Bar';

export default {
  components: { BarChart },
  data() {
    return {
      isSalesChart: true,
    };
  },
  computed: {
    ...mapGetters(['totalSalesReport', 'chartSalesOpts']),
  },
};
</script>

<style></style>
