<template>
  <div>
    <div class="d-flex d-md-none flex-column align-center mb-4" style="gap: 16px">
      <h1 class="mb-0">
        Overview
      </h1>
      <div class="d-flex justify-center" style="gap: 12px">
        <SelectStorePopover :storeSelectWatcher="onSelectedStores" />
        <PickDatePopover :handler="pickedDate" :timezone="pagePreference.standardTimezone || 'America/Los_Angeles'" />
      </div>
    </div>
    <div class="d-none d-md-flex align-center justify-end justify-content-md-center flex-wrap" style="gap: 8px">
      <h1 class="mb-0">
        Overview
      </h1>
      <v-spacer></v-spacer>
      <SelectStorePopover :storeSelectWatcher="onSelectedStores" />
      <PickDatePopover :handler="pickedDate" :timezone="pagePreference.standardTimezone || 'America/Los_Angeles'" />
    </div>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <TotalSales
          :dateRange="dateRange"
          :stores="stores"
          :timezone="pagePreference.standardTimezone || 'America/Los_Angeles'"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <TotalOrders
          :dateRange="dateRange"
          :stores="stores"
          :timezone="pagePreference.standardTimezone || 'America/Los_Angeles'"
        />
      </v-col>
      <v-col cols="12" md="4">
        <AverageOrderValue
          :dateRange="dateRange"
          :stores="stores"
          :timezone="pagePreference.standardTimezone || 'America/Los_Angeles'"
        />
      </v-col>
    </v-row>
    <ConversionRate
      :dateRange="dateRange"
      :stores="stores"
      :timezone="pagePreference.standardTimezone || 'America/Los_Angeles'"
    />
    <Subscriptions />
    <v-row v-if="getTimezoneDone" class="mb-4">
      <v-col cols="12" lg="4">
        <RecentOrders />
      </v-col>
      <v-col cols="12" lg="8">
        <SalesOrdersChart />
      </v-col>
    </v-row>
    <SaleReportTable
      v-if="getTimezoneDone"
      :dateRange="dateRange"
      :stores="stores"
      :timezone="pagePreference.standardTimezone || 'America/Los_Angeles'"
    />
  </div>
</template>

<script>
import ConversionRate from './components-2/ConversionRate';
import TotalOrders from './components-2/TotalOrders';
import TotalSales from './components-2/TotalSales';
import SelectStorePopover from './components/SelectStorePopover';
import PickDatePopover from './components/PickDatePopover';
import AverageOrderValue from './components-2/AverageOrderValue';
import RecentOrders from './components/RecentOrders';
import SalesOrdersChart from './components/SalesOrdersChart';
import SaleReportTable from './components/SaleReportTable.vue';
import moment from 'moment';
import STORAGE_NAME from '@/const/storage';
import Subscriptions from './components/Subscriptions.vue';
import {
  FETCH_CONVERSION_RATE,
  FETCH_TOTAL_ORDERS,
  FETCH_TOTAL_SALES,
  FETCH_AOV_RATE,
  FETCH_AOI_RATE,
  FETCH_PAGE_PREFERRENCE,
  FETCH_STORES,
} from '@/store/actions.type';

export default {
  components: {
    // AbandonedCheckoutsRecovery,
    AverageOrderValue,
    // AverageOrderItems,
    PickDatePopover,
    ConversionRate,
    TotalOrders,
    TotalSales,
    SelectStorePopover,
    RecentOrders,
    SalesOrdersChart,
    SaleReportTable,
    Subscriptions,
    // TrafficSourceTable,
    // TrafficLocationTable,
  },

  data() {
    let query = this.$route.query;
    return {
      width: 2,
      autoRefresh: query?.refresh === 'true' || false,
      refreshInterval: false,
      dateRange: {
        from: query?.from_time,
        to: query?.to_time,
      },
      stores: query?.store?.split(',') || [],
      getTimezoneDone: true,
    };
  },
  async beforeMount() {
    if (!this.pagePreference.standardTimezone) {
      this.getTimezoneDone = false;
      // await this.$store.dispatch(FETCH_PAGE_PREFERRENCE);
      this.getTimezoneDone = true;
    }
  },
  async created() {
    // let { autoRefresh } = this.loadQueryFromCache();
    if (this.refresh) this.autoRefreshChart();
    await this.$store.dispatch(FETCH_STORES);
  },
  methods: {
    pickedDate(dates) {
      this.dateRange = {
        from: dates.startDate,
        to: dates.endDate,
      };
      let query = this.$route.query;
      this.updateQuery({
        stores: query?.stores,
        from_time: moment(this.dateRange.from).format('YYYY-MM-DD'),
        to_time: moment(this.dateRange.to).format('YYYY-MM-DD'),
        tz: query?.tz,
        refresh: query?.refresh,
      });
      // this.cacheQuery({ startDate: this.dateRange.from, endDate: this.dateRange.to });
    },
    onAutoRefreshChange(autoRefresh) {
      this.refresh = autoRefresh;
      let query = this.$route.query;
      this.updateQuery({
        stores: query?.stores,
        from_time: moment(this.dateRange.from).format('YYYY-MM-DD'),
        to_time: moment(this.dateRange.to).format('YYYY-MM-DD'),
        tz: query?.tz,
        refresh: this.refresh,
      });
      // this.cacheQuery({ autoRefresh: autoRefresh.toString() });
      if (this.refresh) {
        this.autoRefreshChart();
      } else {
        clearInterval(this.refreshInterval);
      }
    },
    onSelectedStores(stores) {
      this.stores = stores;
      let query = this.$route.query;
      this.updateQuery({
        stores: this.stores.join(','),
        from_time: query?.from_time,
        to_time: query?.to_time,
        tz: query?.tz,
        refresh: query?.refresh,
      });
      // this.cacheQuery({ stores: this.stores });
    },
    autoRefreshChart() {
      this.refreshInterval = setInterval(async () => {
        let { stores, startDate, endDate } = this.loadQueryFromCache();
        if (!startDate) startDate = moment().format('YYYY-MM-DD');
        await Promise.all([
          this.$store.dispatch(FETCH_CONVERSION_RATE, {
            fromTime: startDate,
            toTime: endDate,
            stores,
            timezone: this.pagePreference.standardTimezone,
          }),
          this.$store.dispatch(FETCH_TOTAL_ORDERS, {
            fromTime: startDate,
            toTime: endDate,
            stores,
            timezone: this.pagePreference.standardTimezone,
          }),
          this.$store.dispatch(FETCH_TOTAL_SALES, {
            fromTime: startDate,
            toTime: endDate,
            stores,
            timezone: this.pagePreference.standardTimezone,
          }),
          this.$store.dispatch(FETCH_AOV_RATE, {
            fromTime: startDate,
            toTime: endDate,
            stores,
            timezone: this.pagePreference.standardTimezone,
          }),
          this.$store.dispatch(FETCH_AOI_RATE, {
            fromTime: startDate,
            toTime: endDate,
            stores,
            timezone: this.pagePreference.standardTimezone,
          }),
        ]);
      }, 60 * 5 * 1000); // 5 minutes
    },
    // cacheQuery(args) {
    //   let currentStorageStr = localStorage.getItem(STORAGE_NAME.REPORT_SEARCH_QUERY);
    //   let currentStorage = currentStorageStr ? JSON.parse(currentStorageStr) : {};
    //   currentStorage.startDate =
    //     args && args.startDate ? moment(args.startDate).format('YYYY-MM-DD') : currentStorage.startDate;
    //   currentStorage.endDate =
    //     args && args.endDate ? moment(args.endDate).format('YYYY-MM-DD') : currentStorage.endDate;
    //   currentStorage.stores = args && args.stores ? args.stores : currentStorage.stores;
    //   currentStorage.autoRefresh = args && args.autoRefresh ? args.autoRefresh : currentStorage.autoRefresh;
    //   localStorage.removeItem(STORAGE_NAME.REPORT_SEARCH_QUERY);
    //   localStorage.setItem(STORAGE_NAME.REPORT_SEARCH_QUERY, JSON.stringify(currentStorage));
    // },
    loadQueryFromCache() {
      let cacheQuery = localStorage.getItem(STORAGE_NAME.REPORT_SEARCH_QUERY);
      return cacheQuery ? JSON.parse(cacheQuery) : {};
    },
    updateQuery(query) {
      this.$router.push({
        query: query,
      });
    },
  },
  computed: {
    // ...mapGetters(['pagePreference']),
    pagePreference() {
      return this.$store.getters.pagePreference;
    },
  },
  destroyed() {
    // No remove auto refresh
    let currentCacheQuery = this.loadQueryFromCache();
    let newCacheQuery = { autoRefresh: currentCacheQuery?.autoRefresh };
    localStorage.removeItem(STORAGE_NAME.REPORT_SEARCH_QUERY);
    localStorage.setItem(STORAGE_NAME.REPORT_SEARCH_QUERY, JSON.stringify(newCacheQuery));
  },
};
</script>
