<template>
  <v-card class="pa-0" outlined>
    <div class="px-4 py-3">
      <h3>Sale Report</h3>
    </div>
    <v-divider></v-divider>
    <v-data-table
      dense
      :loading="loading"
      :headers="headers"
      :items="items"
      :items-per-page="pagination.size"
      :page.sync="pagination.page"
      item-key="_id"
      :hide-default-footer="true"
    >
      <template v-slot:[`item.title`]="{ item }">
        <div class="product-card">
          <img :src="formatImage(item)" />
          <div class="flex-1">
            <router-link
              class="router-link"
              target="_blank"
              :to="{ name: 'website.products.update', params: { id: item._id } }"
            >
              {{ item.title }}
            </router-link>
          </div>
          <button @click.stop="gotoProduct(item)">
            <v-icon :size="18" color="primary">mdi-open-in-new</v-icon>
          </button>
        </div>
      </template>
      <template v-slot:[`item.aov`]="{ item }">
        {{ formatMoney(item.aov) }}
      </template>
      <template v-slot:[`item.total_sale`]="{ item }">
        <span class="font-weight-bold success--text">{{ formatMoney(item.total_sale) }}</span>
      </template>
    </v-data-table>
    <v-divider v-if="pagination.total"></v-divider>
    <div v-if="pagination.total" class="d-flex justify-end mt-2 pb-2">
      <v-pagination
        v-model="pagination.page"
        :length="pagination.total"
        :total-visible="10"
        :visible="7"
        @input="getData"
      ></v-pagination>
    </div>
  </v-card>
</template>

<script>
import { AnalyticService } from '@/apis/analytics';
import imageHelpers from '@/helpers/image';
import moment from 'moment';

export default {
  props: {
    dateRange: {
      from: Date,
      to: Date,
    },
    stores: Array,
    timezone: String,
  },
  data() {
    return {
      items: [],
      loading: false,
      pagination: {
        page: 1,
        limit: 20,
        total: 0,
      },
    };
  },
  computed: {
    headers() {
      return [
        {
          text: 'Product Title',
          align: 'start',
          sortable: false,
          value: 'title',
          width: 300,
        },
        { text: 'CR', value: 'visitors_conversion_rate', sortable: false },
        { text: 'Visitors', value: 'visitors', sortable: false },
        { text: 'Add to cart', value: 'add_to_cart', sortable: false },
        { text: 'Reached checkout', value: 'reached_checkout', sortable: false },
        { text: 'Session Converted', value: 'session_converted', sortable: false },
        { text: 'Order', value: 'orders', sortable: false },
        { text: 'Net quantity', value: 'net_qty', sortable: false },
        { text: 'Average order value', value: 'aov', align: 'end', sortable: false },
        { text: 'Average order items', value: 'aoi', sortable: false },
        {
          text: 'Total sales (Not including shipping costs, discount)',
          value: 'total_sale',
          align: 'end',
          sortable: false,
        },
      ];
    },
  },
  methods: {
    formatMoney(money) {
      if (!money) return '$0';
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(money);
    },
    async getData() {
      try {
        this.loading = true;
        const fromTime = this.dateRange.from
          ? moment(this.dateRange.from)
          : moment().tz(this.timezone || 'America/Los_Angeles');

        const toTime = this.dateRange.to
          ? moment(this.dateRange.to)
          : moment().tz(this.timezone || 'America/Los_Angeles');

        const params = {
          page: this.pagination.page,
          size: this.pagination.limit,
          from_time: fromTime.format('YYYY-MM-DD'),
          to_time: toTime.format('YYYY-MM-DD'),
          stores: this.stores?.join(',') || '',
          timezone: this.timezone,
          hasSale: true,
          sales: true,
        };
        const { data } = await AnalyticService.fetchProductSalesReport(params);
        this.items = data?.products || [];
        this.pagination.total = Math.ceil((data?.total || 0) / params.size);
      } catch (error) {
        console.log('🚀 ~ getData ~ error:', error);
      } finally {
        this.loading = false;
      }
    },
    formatImage(item) {
      if (item.images[0].src) return imageHelpers.url(item.images[0].src, { width: 120, height: 120 });
      return '/admin/img/no-image.png';
    },
    gotoProduct(item) {
      console.log(item);
      //   if (item.handle) {
      //     let store = this.allStores.find(store => item.store == store.id);
      //     console.log(store, item.store);
      //     let domain = store?.storeDetail?.defaultSystemDomain || this.$store.state.auth.accountInfo.rDomain;
      //     window.open('https://' + domain + `/products/${item.handle}`);
      //   }
    },
  },
  watch: {
    dateRange: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    stores: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.product-card {
  display: flex;
  align-items: center;
  padding: 12px 0;
  gap: 12px;
  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 8px;
    border: solid 1px var(--v-border-base);
  }
  a.router-link {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 2 !important;
    font-size: 14px !important;
  }
}
</style>
