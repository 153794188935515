import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);
export const notificationService = {
  getNotifications(params) {
    return storeRequest.get('/stores/payout/v2/notification', { params });
  },
  maskReadNotification(id) {
    return storeRequest.patch('/stores/payout/v2/notification/' + id);
  },
};
