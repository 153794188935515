var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column align-center justify-center",staticStyle:{"gap":"24px"}},[_c('Logo'),_c('v-card',{staticClass:"login-form pa-7"},[_c('h2',{staticClass:"mb-4 text-center"},[_vm._v("Sign in")]),(_vm.sk === '4209539192748903198293100002204481')?_c('div',{staticClass:"label-forgot mb-4"},[_vm._v(" Don't have an account? "),_c('strong',{staticClass:"text-decoration-underline pointer",on:{"click":function($event){return _vm.$router.push({ path: '/auth/register', query: Object.assign({}, _vm.$route.query) })}}},[_vm._v("Create new account")])]):_vm._e(),(_vm.errorMessage.length > 0)?_c('Notification',{attrs:{"times":1000000000000000,"messages":_vm.errorMessage,"type":"error"}}):_vm._e(),_c('div',[_c('label',[_vm._v(" Email ")]),_c('v-text-field',{attrs:{"autocomplete":"off","name":"email","id":"email","error-messages":_vm.invalid && !_vm.$v.email.required
            ? _vm.$t('Email is required')
            : _vm.invalid && !_vm.$v.email.email
            ? _vm.$t('Invalid Email')
            : _vm.invalid && !_vm.$v.email.minLength
            ? _vm.$t('Email must have at least ' + _vm.$v.email.$params.minLength.min + ' letters.')
            : _vm.invalid && !_vm.$v.email.maxLength
            ? _vm.$t('Email must have at least ' + _vm.$v.email.$params.maxLength.max + ' letters.')
            : ''},model:{value:(_vm.$v.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.email, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.email.$model"}})],1),_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('label',[_vm._v(" Password ")]),_c('router-link',{staticClass:"font-weight-medium router-link",attrs:{"to":{ path: '/auth/forgot', query: Object.assign({}, _vm.$route.query) }}},[_vm._v(" Forgot password? ")])],1),_c('v-text-field',{attrs:{"type":_vm.type,"error-messages":_vm.invalid && !_vm.$v.password.required
            ? _vm.$t('Password is required')
            : _vm.invalid && !_vm.$v.password.minLength
            ? _vm.$t('Password must have at least ' + _vm.$v.password.$params.minLength.min + ' letters.')
            : _vm.invalid && !_vm.$v.password.maxLength
            ? _vm.$t('Password must have at least ' + _vm.$v.password.$params.maxLength.max + ' letters.')
            : '',"autocomplete":"off","name":"password","id":"password"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onLogin($event)}},model:{value:(_vm.$v.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.password, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.password.$model"}},[_c('template',{slot:"append"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.type = _vm.type === 'text' ? 'password' : 'text'}}},[(_vm.type == 'text')?_c('v-icon',{attrs:{"size":20}},[_vm._v("mdi-eye")]):_c('v-icon',{attrs:{"size":20}},[_vm._v("mdi-eye-off-outline")])],1)],1)],2)],1),_c('v-btn',{staticClass:"mb-4",attrs:{"loading":_vm.isLoading,"block":"","color":"primary","depressed":""},on:{"click":_vm.onLogin}},[_vm._v(" Sign In ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" Contact for new store: "),_c('a',{staticClass:"font-weight-medium",attrs:{"href":"https://t.me/DcomcyEcommerce","target":"_blank"}},[_vm._v(" Telegram ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }