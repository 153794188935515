<template>
  <section class="trial-box" v-if="$route.name !== 'website.themes.editor' ? trialInfo.status : false">
    <span class="white--text mr-5">{{ trialInfo.dayLeft }} </span>
    <v-btn color="primary" depressed @click="$router.push({ name: 'website.settings.account' })">Select a plan</v-btn>
  </section>
</template>

<script>
export default {
  computed: {
    trialInfo() {
      return this.$store.state.subscription.trialInfo;
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.trial-box {
  background-color: #252733;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 0 6px 240px;
  position: fixed;
  bottom: 0;
  top: auto;
  left: 0;
  right: 0;
  z-index: 100;
  span {
    font-size: 14px;
  }
}
@media screen and (max-width: 850px) {
  .trial-box {
    padding: 8px 0;
  }
}
</style>
