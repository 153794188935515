<template>
  <div class="d-flex flex-column align-center justify-center" style="gap: 24px">
    <Logo />
    <v-card class="login-form pa-7">
      <h2 class="mb-4 text-center">Sign in</h2>
      <div v-if="sk === '4209539192748903198293100002204481'" class="label-forgot mb-4">
        Don't have an account?
        <strong
          class="text-decoration-underline pointer"
          @click="$router.push({ path: '/auth/register', query: { ...$route.query } })"
          >Create new account</strong
        >
      </div>
      <Notification :times="1000000000000000" v-if="errorMessage.length > 0" :messages="errorMessage" type="error" />
      <div>
        <label> Email </label>
        <v-text-field
          autocomplete="off"
          name="email"
          id="email"
          v-model.trim="$v.email.$model"
          :error-messages="
            invalid && !$v.email.required
              ? $t('Email is required')
              : invalid && !$v.email.email
              ? $t('Invalid Email')
              : invalid && !$v.email.minLength
              ? $t('Email must have at least ' + $v.email.$params.minLength.min + ' letters.')
              : invalid && !$v.email.maxLength
              ? $t('Email must have at least ' + $v.email.$params.maxLength.max + ' letters.')
              : ''
          "
        ></v-text-field>
      </div>
      <div class="mb-3">
        <div class="d-flex align-center justify-space-between">
          <label> Password </label>
          <router-link
            class="font-weight-medium router-link"
            :to="{ path: '/auth/forgot', query: { ...$route.query } }"
          >
            Forgot password?
          </router-link>
        </div>

        <v-text-field
          v-on:keyup.enter="onLogin"
          :type="type"
          v-model.trim="$v.password.$model"
          :error-messages="
            invalid && !$v.password.required
              ? $t('Password is required')
              : invalid && !$v.password.minLength
              ? $t('Password must have at least ' + $v.password.$params.minLength.min + ' letters.')
              : invalid && !$v.password.maxLength
              ? $t('Password must have at least ' + $v.password.$params.maxLength.max + ' letters.')
              : ''
          "
          autocomplete="off"
          name="password"
          id="password"
        >
          <template slot="append">
            <v-btn @click="type = type === 'text' ? 'password' : 'text'" icon small
              ><v-icon v-if="type == 'text'" :size="20">mdi-eye</v-icon>
              <v-icon v-else :size="20">mdi-eye-off-outline</v-icon></v-btn
            >
          </template>
        </v-text-field>
      </div>
      <v-btn @click="onLogin" :loading="isLoading" class="mb-4" block color="primary" depressed>
        Sign In
      </v-btn>
      <p class="mb-0">
        Contact for new store:
        <a href="https://t.me/DcomcyEcommerce" target="_blank" class="font-weight-medium">
          Telegram
        </a>
      </p>
    </v-card>
  </div>
</template>

<script>
import STORAGE_NAME from '@/const/storage';
import { authService } from '@/apis/auth.s';
import Notification from '@/components/Notification';
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators';
import accountRequest from '@/apis/request/accountRequest';
import Logo from './Logo.vue';
// import Back from './Back.vue';

export default {
  components: {
    Notification,
    Logo,
  },
  data() {
    return {
      type: 'password',
      email: null,
      password: null,
      isLoading: false,
      invalid: false,
      errorMessage: [],
      sk: null,
    };
  },
  methods: {
    /*
    Fnc login
    */
    async onLogin() {
      this.isLoading = true;
      this.$v.$touch();
      this.invalid = this.$v.$invalid;
      this.errorMessage = [];
      if (!this.$v.$invalid) {
        try {
          window.localStorage.clear();
          let data = await authService.login(this.email, this.password);
          window.localStorage.setItem(STORAGE_NAME.ACCESS_TOKEN, data.data.accessToken || '');
          window.localStorage.setItem(STORAGE_NAME.REFRESH_TOKEN, data.data.refreshToken || '');
          window.localStorage.setItem(STORAGE_NAME.STORE_ID, data.data.storeId || '');
          //settoken
          accountRequest.setToken(data.data.accessToken);
          this.$store.commit('setAuthCheck', true);
          this.$router.push('/auth/shop');
          this.isLoading = false;
        } catch (error) {
          console.log('error', error);
          this.errorMessage = error;
          this.isLoading = false;
        }
      } else {
        this.isLoading = false;
      }
    },
  },
  created() {
    let TOKEN = localStorage.getItem(STORAGE_NAME.ACCESS_TOKEN);
    let REFRESH_TOKEN = localStorage.getItem(STORAGE_NAME.REFRESH_TOKEN);
    let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
    if (this.$route.query?.sk) {
      this.sk = this.$route.query.sk;
    }
    if (TOKEN && this.$route.params.authPage !== 'accept' && this.$route.params.authPage !== 'verify') {
      this.$router.push({ name: 'auth', params: { authPage: 'shop' } });
    }
  },
  validations: {
    email: {
      required,
      email,
      minLength: minLength(5),
      maxLength: maxLength(50),
    },
    password: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(50),
    },
  },
};
</script>
<style lang="scss" scoped>
.login-form {
  width: 475px;
}
</style>
