<template>
  <div class="mr-2">
    <v-menu offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" @click="openDrawer = !openDrawer">
          <v-badge v-if="total" color="red" overlap>
            <span slot="badge">{{ total }}</span>
            <Icon icon="hugeicons:notification-02" :width="22" :height="22" />
          </v-badge>
          <Icon v-else icon="hugeicons:notification-02" :width="22" :height="22" />
        </v-btn>
      </template>
      <v-card class="pa-0" style="border-radius: 0 !important;" :width="375">
        <div class="pa-4">
          <h3 class="primary--text fz-18">Thông Báo</h3>
        </div>
        <template v-if="loading">
          <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
          <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
        </template>
        <template v-else>
          <div v-if="total === 0" class="px-4 pb-4">
            <p class="grey--text mb-0">Không có thông báo mới</p>
          </div>
          <v-list v-else class="py-0" dense>
            <v-list-item
              v-for="(item, index) in notifications"
              :key="index"
              class="pa-0"
              @click="onSelectNotification(item)"
              link
            >
              <div class="notification px-4 py-3">
                <div class="notification-icon">
                  <Icon icon="hugeicons:notification-02" :width="24" :height="24" class="primary--text" />
                </div>
                <div class="flex">
                  <p class="notification-title">{{ item.title }}</p>
                  <p class="notification-time">{{ formatTime(item.createdDate) }}</p>
                  <p class="notification-body">{{ item.body }}</p>
                </div>
              </div>
            </v-list-item>
          </v-list>
        </template>
      </v-card>
    </v-menu>
    <v-dialog v-model="openDetailDialog" :width="500">
      <v-card v-if="selected != null" class="pa-4" style="border-radius: 0px !important;">
        <h3 class="primary--text fz-20 mb-2">Thông báo</h3>
        <h3 class="mb-1">{{ selected.title }}</h3>
        <p>{{ selected.body }}</p>
        <div class="d-flex justify-end">
          <v-btn text outlined @click="openDetailDialog = false">Đóng</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { Icon } from '@iconify/vue2';
import { notificationService } from '@/apis/notification';
import moment from 'moment';
export default {
  components: { Icon },
  data() {
    return {
      openDrawer: false,
      notifications: [],
      total: 1,
      filters: {
        page: 1,
        limit: 10,
        status: 1,
      },
      loading: false,
      openDetailDialog: false,
      selected: null,
    };
  },
  methods: {
    async getNotifications() {
      try {
        this.loading = true;
        const { data } = await notificationService.getNotifications(this.filters);
        this.notifications = data?.notifications || [];
        this.total = data?.total || 0;
      } catch (error) {
        console.log('🚀 ~ getNotifications ~ error:', error);
      } finally {
        this.loading = false;
      }
    },
    formatTime(time) {
      return moment(time).format('DD-MM-YYYY, HH:mm:ss');
    },
    async onSelectNotification(notification) {
      try {
        this.selected = notification;
        this.openDetailDialog = true;
        if (notification.status === 2) return;
        await notificationService.maskReadNotification(notification._id);
        this.getNotifications();
      } catch (error) {
        console.log('🚀 ~ onSelectNotification ~ error:', error);
      }
    },
  },
  created() {
    this.getNotifications();
  },
};
</script>
<style lang="scss" scoped>
.notification-tab {
  font-size: 14px;
  letter-spacing: unset;
  text-transform: capitalize;
}
.notification-tab-active {
  box-shadow: 0px 2px 10px 0px rgba(124, 141, 181, 0.12);
  border-radius: 8px;
}
.notification {
  display: flex;
  align-items: flex-start;
  border-top: dotted 1px #cecece;
  gap: 12px;
  &-icon {
    margin-top: 4px;
    background-color: rgb(233 233 233);
    flex-shrink: 0;
    padding: 8px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-title {
    margin-bottom: 2px;
    font-size: 14px;
    font-weight: 600;
  }
  &-time {
    margin-bottom: 0px;
    font-size: 12px;
    opacity: 0.6;
    font-weight: 500;
    color: #70777e;
  }
  &-body {
    margin-bottom: 0px;
    font-size: 13px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
}
</style>
