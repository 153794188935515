<template>
  <v-card class="pa-6 d-flex align-center" style="gap: 16px" outlined>
    <div>
      <div class="card-icon green lighten-5 pa-4">
        <div class="success">
          <i class="ri-wallet-line ri-lg white--text"></i>
        </div>
      </div>
    </div>
    <div class="flex foreground--text">
      <p class="mb-2">Total Sales</p>
      <h2>{{ currency.priceFormat(totalSales) }}</h2>
    </div>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { FETCH_TOTAL_SALES } from '@/store/actions.type';
import STORAGE_NAME from '@/const/storage';
import currency from '@/helpers/currency';

export default {
  props: {
    dateRange: {
      from: Date,
      to: Date,
    },
    stores: Array,
    timezone: String,
  },
  data: function() {
    return {
      isLoading: false,
      currency,
    };
  },
  async created() {
    this.isLoading = true;
    let { startDate, endDate, stores } = this.loadQueryFromCache();
    let fromTime = startDate
      ? startDate
      : this.$props.dateRange && this.$props.dateRange.from
      ? moment(this.$props.dateRange.from).format('YYYY-MM-DD')
      : moment()
          .tz(this.timezone)
          .format('YYYY-MM-DD');
    let toTime = endDate
      ? endDate
      : this.$props.dateRange && this.$props.dateRange.to
      ? moment(this.$props.dateRange.to).format('YYYY-MM-DD')
      : moment()
          .tz(this.timezone)
          .format('YYYY-MM-DD');
    let _stores = stores ? stores : this.$props.stores ? this.$props.stores : '';
    await this.$store.dispatch(FETCH_TOTAL_SALES, { fromTime, toTime, stores: _stores, timezone: this.timezone });
    this.isLoading = false;
  },
  computed: {
    ...mapGetters(['totalSalesReport', 'chartSalesOpts']),
    totalSales() {
      return this.$store.getters.totalSales;
    },
    uselTotalSales() {
      return this.$store.getters.uselTotalSales;
    },
    onlineStoreSales() {
      return this.$store.getters.onlineStoreSales;
    },
  },
  methods: {
    loadQueryFromCache() {
      let cacheQuery = localStorage.getItem(STORAGE_NAME.REPORT_SEARCH_QUERY);
      return cacheQuery ? JSON.parse(cacheQuery) : {};
    },
  },
  watch: {
    dateRange: async function(newTime, currTime) {
      let { stores } = this.loadQueryFromCache();
      let fromTime = newTime
        ? moment(newTime.from).format('YYYY-MM-DD')
        : moment()
            .tz(this.timezone)
            .format('YYYY-MM-DD');
      let toTime =
        newTime && newTime.to
          ? moment(newTime.to).format('YYYY-MM-DD')
          : moment()
              .tz(this.timezone)
              .format('YYYY-MM-DD');
      await this.$store.dispatch(FETCH_TOTAL_SALES, {
        fromTime,
        toTime,
        stores: this.stores,
        timezone: this.timezone,
      });
    },
    stores: async function(newStores, currStores) {
      let { startDate, endDate } = this.loadQueryFromCache();
      let fromTime = startDate
        ? startDate
        : this.$props.dateRange && this.$props.dateRange.from
        ? moment(this.$props.dateRange.from).format('YYYY-MM-DD')
        : moment()
            .tz(this.timezone)
            .format('YYYY-MM-DD');
      let toTime = endDate
        ? endDate
        : this.$props.dateRange && this.$props.dateRange.to
        ? moment(this.$props.dateRange.to).format('YYYY-MM-DD')
        : moment()
            .tz(this.timezone)
            .format('YYYY-MM-DD');
      await this.$store.dispatch(FETCH_TOTAL_SALES, { fromTime, toTime, stores: newStores, timezone: this.timezone });
    },
  },
};
</script>
<style lang="scss" scoped>
.card-icon {
  border-radius: 6px;
  div {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
  }
}
</style>
