<template>
  <v-card class="pa-0" height="100%" outlined>
    <div class="px-4 py-3">
      <h3>Recent Orders</h3>
    </div>
    <v-divider></v-divider>
    <div v-if="loading">
      <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
      <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
      <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
    </div>
    <v-list v-else-if="orders.length">
      <v-list-item v-for="(order, index) in orders" :key="index">
        <v-list-item-avatar rounded>
          <v-img :alt="order.orderName" :src="getOrderImage(order.items)"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">{{ order.orderName }}</v-list-item-title>
          <v-list-item-subtitle>{{ order.customerInfo[0].full_name }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <span class="success--text font-weight-medium">{{ formatMoney(order.paidInfo.sub_total) }}</span>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <div v-else class="pa-4">
      <p>No orders</p>
    </div>
  </v-card>
</template>

<script>
import { orderApi } from '@/apis/order';
import imageHelpers from '@/helpers/image';

export default {
  data() {
    return { orders: [], loading: true };
  },
  methods: {
    async getOrders() {
      try {
        this.loading = true;
        const params = {
          page: 1,
          limit: 9,
        };
        const { data } = await orderApi.get(params);
        this.orders = data || [];
      } catch (error) {
        console.log('🚀 ~ getOrders ~ error:', error);
      } finally {
        this.loading = false;
      }
    },
    getOrderImage(items) {
      return imageHelpers.url(items[0].image, { width: 120, height: 120 });
    },
    formatMoney(money) {
      if (!money) return '$0';
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(money);
    },
  },
  created() {
    this.getOrders();
  },
};
</script>

<style></style>
