<script>
import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  // mixins: [mixins.reactiveProp],
  props: {
    chartdata: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.renderChart(this.chartdata, this.options);
  },
  watch: {
    chartdata() {
      // this.$data._chart.update(this.chartdata, this.options);
      this.renderChart(this.chartdata, this.options);
    },
  },
};
</script>
