import draggable from 'vuedraggable';
import toBase64 from '@/helpers/toBase64';
import { apiThemes } from '@/apis/theme.js';
import { productApi } from '@/apis/product';
import { collectionApi } from '@/apis/collection';
import { imageFake } from '@/const/image';
import { menuService } from '@/apis/menu.s';
import settingData from './settings-data.json';
import ItemSection from './components/ItemSection';
import ElementSetting from './components/ElementSetting';
import CurrencySetting from './components/CurrencySetting';
import ItemSeparate from './components/ItemSeparate';
import SectionType from './components/SectionType';
import verifyMixin from '@/mixins/verify';
import {
  ImageWithText,
  ProductReview,
  SliderProduct,
  HeadImage,
  CollectionList,
  FeatureContent,
  FeatureCollection,
  ProductSetting,
  CartPageSetting,
  ThankpageSetting,
  Colors,
  Typography,
  EditHeader,
  EditFooter,
} from './components/driver-theme64/index.js';
import {
  SummerStyles,
  EditFooterNewChic,
  EditHeaderNewChic,
  ProductWithCollection,
  Checkout,
} from './components/newchic/index.js';
import css from './css';
import { blockDefaultHomePage, blockDefaultThankyouPage } from './default-block';
import settingDataDefault from './settings-data.json';
import settingDataDefaultNewchic from './setting-data-newchic.json';
import settingDataDefaultHydro from './settings-data.json';
import { mapGetters, mapState } from 'vuex';
import { FETCH_PAGE_PREFERRENCE } from '@/store/actions.type';

export default {
  mixins: [verifyMixin],
  components: {
    HeadImage,
    ItemSeparate,
    ItemSection,
    ProductSetting,
    CartPageSetting,
    css,
    SectionType,
    ImageWithText,
    ProductWithCollection,
    draggable,
    ProductReview,
    SliderProduct,
    CollectionList,
    FeatureContent,
    ThankpageSetting,
    FeatureCollection,
    Colors,
    Typography,
    SummerStyles,
    EditHeader,
    EditFooter,
    EditFooterNewChic,
    EditHeaderNewChic,
    Checkout,
    ElementSetting,
    CurrencySetting,
  },
  data() {
    return {
      settingDataDefaultNewchic,
      settingDataDefaultHydro,
      blockDefaultHomePage,
      blockDefaultThankyouPage,
      scrollToId: 'header',
      sectionDrag: [],
      isAddSection: false,
      optionAddSection: {},
      imageFake,
      deviceView: 'desktop',
      tab: 'section',
      isLoading: true,
      selectPage: 'home',
      isSelectSection: false,
      selectSection: 'Header',
      loaded: false,
      image: '',
      isLoadingSubmit: false,
      configHeader: {},
      logoDefault: imageFake.LogoTheme,
      configFooter: {},
      urlTheme: '',
      config: [],
      mainMenu: [],
      settingData,
      configSection: {},
      isScroll: false,
      pageCurrent: 'homepage',
      settingDataDefault,
      nameTheme: `hydro`,
      product: {},
      collection: {},
      isClick: false,
      domainCheck: ``,
      showFullSettings: false,
      whiteEmailList: ['support@dcomcy.com', 'account@ak11.work'],
    };
  },
  async created() {
    await this.getConfigTheme();

    this.getMenu();
  },
  async mounted() {
    this.convertDataPageObjectToArray();
    // this.nameTheme = localStorage.getItem(`nameTheme`);
    // if (this.$el.querySelector('iframe')) {
    // }
    setTimeout(() => {
      this.isClick = true;
    }, 1000);
    // await this.loadProduct();
    // await this.loadCollection();
    window.addEventListener('message', event => {
      try {
        const d = JSON.parse(event.data);
        if (d.channel) {
          switch (d.messageType) {
            case 'PREVIEW_SITE_MOUNTED':
              console.log('PONG');
              break;
          }
        }
      } catch (ex) {}
    });

    let u = null;
    if (localStorage.getItem('dcomcy-user')) {
      u = JSON.parse(localStorage.getItem('dcomcy-user'));
    }
    console.log('user >> ', u.email, this.whiteEmailList.indexOf(u?.email));
    if (
      u?.email?.indexOf('@lattehub.com') > -1 ||
      this.whiteEmailList.indexOf(u?.email) >= 0 ||
      this.pagePreference?.paymentChoice?.type === 'seller'
    ) {
      console.log('hihi');
      this.showFullSettings = true;
    }
  },
  computed: {
    pageSettings() {
      return this.settingData.pages[this.pageCurrent];
    },
    settings() {
      return this.settingData.settings;
    },
    pageFixedSettings() {
      // this.changeConfig();
      if (this.nameTheme === 'newchick') {
        if (
          this.settingData.pages['fixed'].header &&
          this.settingData.pages['fixed'].header.settings &&
          !this.settingData.pages['fixed'].header.settings.size_logo
        ) {
          this.settingData.pages['fixed'].header.settings.size_logo = {
            desktop: 50,
            mobile: 32,
          };
        }
        if (
          this.settingData.pages['fixed'].footer &&
          this.settingData.pages['fixed'].footer.settings &&
          !this.settingData.pages['fixed'].footer.settings.size_logo
        ) {
          this.settingData.pages['fixed'].footer.settings.size_logo = {
            desktop: 50,
            mobile: 32,
          };
        }
      }
      return this.settingData.pages['fixed'];
    },
    configJson: {
      get: function() {
        return this.attributes.config && this.attributes.config.length ? JSON.stringify(this.attributes.config) : '[]';
      },
      set: function(json) {
        try {
          let data = JSON.parse(json);
          if (Array.isArray(data)) {
            this.attributes.config = data;
          }
        } catch (e) {
          //empty
        }
      },
    },
    storeFrontDefaultTheme() {
      return this.$store.state.pagePreference.preference;
      // Or return basket.getters.fruitsCount
      // (depends on your design decisions).
    },
    ...mapState({
      pagePreference: state => state.pagePreference.preference,
    }),
  },
  watch: {
    storeFrontDefaultTheme() {
      // this.nameTheme = localStorage.setItem(`nameTheme`, this.nameTheme);
    },
    sectionDrag() {},
    selectPage() {
      this.pageCurrent = this.getPageCurrentSetting(this.selectPage);
    },
    pageSettings() {
      this.convertDataPageObjectToArray();
    },
    deviceView: function() {
      this.changeConfig();
    },
    '$store.state.pagePreference.preference'() {
      if (localStorage.getItem('dcomcy-user')) {
        let u = JSON.parse(localStorage.getItem('dcomcy-user'));
        if (
          u?.email?.indexOf('@lattehub.com') > -1 ||
          this.whiteEmailList.indexOf(u?.email) >= 0 ||
          this.pagePreference?.paymentChoice?.type === 'seller'
        ) {
          this.showFullSettings = true;
        }
      }
    },
  },
  methods: {
    getPageCurrentSetting(page) {
      let keyPage = '';
      switch (page) {
        case 'home':
          keyPage = 'homepage';
          break;
        // case 'order':
        //   keyPage = 'thankpage';
        //   break;
      }
      return keyPage ? keyPage : this.pageCurrent;
    },
    getCallbackRemoveSection(item) {
      delete this.pageSettings[item.key];
      this.convertDataPageObjectToArray();
      this.changeConfig();
      this.isSelectSection = false;
    },
    getCallbackRemoveConent(array, i) {
      array.splice(i, 1);
      this.changeConfig();
    },
    getCallbackAddContent(arr, type) {
      let imageBlockFake =
        process.env.NODE_ENV === 'development'
          ? '/601a0d4860ebbe0e58df4c5f/2021/03/11/lattehub-image-60499aec69488a35719d2c4e.png'
          : '/60924e756675a342081d1a8f/2021/05/12/lattehub-image-609b55931062db6e53184092.png';
      if (type === 'image_with_text') {
        arr.push({
          image: imageBlockFake,
          heading: 'Your headline',
          show_button: false,
          content: 'Promotion description appears here.',
          button_label: 'VIEW COLLECTION',
          url_button: '/',
          position: 'left',
        });
      }
      if (type === 'slider-product') {
        arr.push({
          heading: 'Heading',
          item_per_row: 4,
        });
      }
      if (type === 'count_down') {
        arr.push({
          type: 'count-down',
          url: {
            name: 'category',
            slug: null,
          },
          image: 'https://cdn.btdmp.com/dist/themes/3/3/images/shoes.4b48f7d2.svg',
          time: {
            day: 5,
            hour: 0,
            min: 0,
            getTime: new Date().getTime() + 5 * 24 * 60 * 60 * 1000,
          },
        });
      }
      if (type === 'collection_and_product') {
        arr.push({
          type: 'collection',
          url: {
            name: 'category',
            slug: null,
          },
          productId: null,
        });
      }
      if (type === 'product_with_collection') {
        arr.push({
          url: { name: 'category' },
          image: 'https://cdn.btdmp.com/dist/themes/3/3/images/shoes.4b48f7d2.svg',
        });
      }
      if (type === 'collection_list') {
        arr.push({
          title: 'Collection title',
          url: { name: 'category' },
          image: 'https://cdn.btdmp.com/dist/themes/3/3/images/shoes.4b48f7d2.svg',
        });
      }
      this.changeConfig();
    },
    convertDataPageObjectToArray() {
      try {
        this.sectionDrag = [];
        for (let page in this.pageSettings) {
          if (this.pageSettings[page].type !== 'slider-product') {
            this.sectionDrag.push({
              type: this.pageSettings[page].type,
              key: page,
              settings: this.pageSettings[page]['settings'],
            });
          }
        }
        this.$forceUpdate();
      } catch (error) {
        console.log(error);
      }
    },
    convertDataPageArrayToObject() {
      let object = {};
      try {
        for (let i = 0; i < this.sectionDrag.length; i++) {
          object[this.sectionDrag[i].key] = {
            type: this.sectionDrag[i].type,
            settings: this.sectionDrag[i].settings ? JSON.parse(JSON.stringify(this.sectionDrag[i].settings)) : {},
          };
        }
        this.settingData.pages[this.pageCurrent] = JSON.parse(JSON.stringify(object));
        this.$forceUpdate();
        this.changeConfig();
      } catch (error) {
        console.log(error);
      }
    },
    change(evt) {
      this.convertDataPageArrayToObject();
    },
    log(evt) {},
    getCallBackSubmitSelectAddSection(type) {
      this.optionAddSection = {
        ...this.optionAddSection,
        type: type,
      };
      this.isAddSection = false;
      this.AddSection();
    },
    showSectionAdd(position, settings, index) {
      this.optionAddSection = {
        ...this.optionAddSection,
        position: position,
        index: index,
      };
      this.isAddSection = true;
    },
    AddSection() {
      let newBlock = this.blockDefaultHomePage[this.optionAddSection.type]
        ? this.blockDefaultHomePage[this.optionAddSection.type]
        : null;
      if (newBlock) {
        if (this.optionAddSection.index !== this.pageSettings.length - 1) {
          this.sectionDrag.splice(this.optionAddSection.index, 0, {
            ...newBlock,
            key:
              `${this.optionAddSection.type}-` +
              Math.random()
                .toString(36)
                .substring(7),
          }); // chèn 'drum' vào vị trí 2
        } else {
          this.sectionDrag.push({
            ...newBlock,
            key:
              `${this.optionAddSection.type}-` +
              Math.random()
                .toString(36)
                .substring(7),
          }); // c
        }
        this.convertDataPageArrayToObject();
        this.$forceUpdate();
      }
    },
    async getMenu() {
      try {
        let menu = await menuService.list();
        this.mainMenu = menu.data;
      } catch (error) {
        //empty
      }
    },
    getImageUpLoad(images) {},
    async submitConfig() {
      try {
        this.isLoadingSubmit = true;
        if (!this.settingData.pages.homepage[`product-widget`]) {
          this.settingData.pages.homepage[`product-widget`] = {
            settings: {
              body_html: 'html .....',
            },
            type: 'product-widget',
          };
        }

        await apiThemes.updateConfig(this.$route.params.themeId, this.settingData.id, this.settingData);
        // await this.getConfigTheme();
        // this.changeConfig();
      } catch (error) {
        console.log('error', error);
        //empty
      }
      this.isLoadingSubmit = false;
    },
    async getConfigTheme() {
      try {
        let res = await apiThemes.getConfig(this.$route.params.themeId);
        this.settingData = null;
        if (res.data.length > 0) {
          let data = res.data[0];
          this.nameTheme = data.pages.fixed.footer.settings ? 'newchick' : 'hydro';
          if (this.nameTheme == 'hydro') {
            if (!data.pages.fixed.header.settings) {
              data.pages.fixed.header.settings = { ...this.settingDataDefault.pages.fixed.header.settings };
            }
          }
          // console.log(' this.nameTheme)', this.nameTheme);
          if (!data.settings.thankpage) {
            data.settings.thankpage = this.blockDefaultThankyouPage;
          }
          if (!data.settings.colors) {
            data.settings.colors = {
              color_announcement_bar_background: '#000000',
              color_title_product: '#000000',
              color_product_icon: '#333333',
              color_price: '#0d9ef2',
              color_sale_price: '#0d9ef2',
              color_top_bar_background: '#000000',
              color_cart_button: '#f29e0d',
              color_cart_button_label: '#ffffff',
              color_compare_at_price: '#bdbdbd',
              footer_background: this.nameTheme === 'newchick' ? `#f7f7f7` : `#ffffff`,
              color_announcement_bar_text: `#ffffff`,
              color_footer_text: `#000000`,
            };
          }
          if (!data.settings.cart) {
            data.settings.cart = this.settingDataDefault.settings.cart;
          }
          if (!data.settings.element) {
            data.settings.element = this.settingDataDefault.settings.element;
          }
          if (!data.settings.currency) {
            data.settings.currency = this.settingDataDefault.settings.currency;
          }
          // data.settings.element = this.settingDataDefault.settings.element;
          if (!data.settings.typography || (data.settings.typography && !data.settings.typography.type_base_family)) {
            let fontDefault = {
              type_base_family: {
                font: null,
                url: '',
                style: 'regular',
                key: '',
              },
              type_button_base_family: {
                font: null,
                url: '',
                style: 'regular',
                key: '',
              },
              type_header_family: {
                font: null,
                url: '',
                style: 'regular',
                key: '',
              },
              type_product_description_family: {
                font: null,
                url: '',
                style: 'regular',
                key: '',
              },
            };

            if (this.nameTheme === 'hydro') {
              data.settings.typography = {
                ...this.settingDataDefault.settings.typography,
                ...data.settings.typography,
                ...fontDefault,
              };
            }
            if (this.nameTheme === 'newchick') {
              data.settings.typography = {
                ...this.settingDataDefaultNewchic.settings.typography,
                ...data.settings.typography,
                ...fontDefault,
              };
            }
          }
          if (!data.settings.typography.type_product_description_family) {
            data.settings.typography.type_product_description_family = {
              font: null,
              url: '',
              style: 'regular',
              key: '',
            };
          }
          if (!data.settings.product.show_qty) {
            data.settings.product.show_qty = this.settingDataDefault.settings.product.show_qty;
          }
          if (!data.settings.product.trust_badge) {
            data.settings.product.trust_badge = this.settingDataDefault.settings.product.trust_badge;
          }
          if (!data.settings.product.layout) {
            data.settings.product.layout = this.settingDataDefault.settings.product.layout;
          }
          if (!data.settings.product.options_setting) {
            data.settings.product.options_setting = this.settingDataDefault.settings.product.options_setting;
          }
          if (!data.settings.product.position_tab_detail) {
            data.settings.product.position_tab_detail = this.settingDataDefault.settings.product.position_tab_detail;
          }
          if (!data.settings.product.setting_shipping) {
            data.settings.product.setting_shipping = this.settingDataDefault.settings.product.setting_shipping;
          }
          if (!data.settings.product.tags) {
            data.settings.product.tags = this.settingDataDefault.settings.product.tags;
          }
          if (data.settings.product.productRating === undefined || data.settings.product.productRating) {
            data.settings.product.productRating = true;
          }
          if (data.settings.product.hideReviewSizes === undefined || !data.settings.product.hideReviewSizes) {
            data.settings.product.hideReviewSizes = false;
          }
          /// theme newchic
          if (!data.settings.checkout) {
            data.settings.checkout = {
              time: {
                day: 0,
                hour: 0,
                min: 10,
                getTime: new Date().getTime() + 10 * 60 * 1000,
              },
              show_time_countdown: true,
              logo_checkout: null,
              customHtml: null,
              customJs: null,
              step: 1,
            };
          }
          if (!data.settings.style) {
            data.settings.style = {
              maxLineDisplayProduct: 1,
              maxLineDisplayProductDetailDesktop: null,
              maxLineDisplayProductDetailMobile: null,
            };
          }
          if (!data.settings.category) {
            data.settings.category = {
              banner_desktop: 'https://minio.lattehub.com/lattehub/icons-2021-07-07/l83Y8mIyJp1632903562.gif',
              banner_mobile: 'https://minio.lattehub.com/lattehub/icons-2021-07-07/xKmV2SM7Cf1632903582.gif',
              show_banner: false,
              url: {
                name: '',
                params: {
                  slug: '',
                },
              },
            };
          }
          if (!data.settings.product.image_detail) {
            data.settings.product.image_detail = this.settingDataDefaultNewchic.settings.product.image_detail;
          }
          if (data.settings.product.setting_shipping && !data.settings.product.setting_shipping.policy_info) {
            data.settings.product.setting_shipping.policy_info = this.settingDataDefaultNewchic.settings.product.setting_shipping.policy_info;
          }
          if (
            data.settings.product.setting_shipping &&
            !data.settings.product.setting_shipping.shipping_info.page_detail
          ) {
            data.settings.product.setting_shipping.shipping_info.page_detail = `shipping-policy`;
          }
          if (!data.settings.popup) {
            data.settings.popup = this.settingDataDefaultNewchic.settings.popup;
          }
          if (data.settings.popup && !data.settings.popup.discount) {
            data.settings.popup.discount = this.settingDataDefaultNewchic.settings.popup.discount;
          }
          if (!data.settings.cart_goal || (data.settings.cart_goal && !data.settings.cart_goal.cart_goal)) {
            data.settings.cart_goal = this.settingDataDefaultNewchic.settings.cart_goal;
          }
          if (!data.settings.product.heading) {
            data.settings.product.heading = this.settingDataDefaultNewchic.settings.product.heading;
          }
          this.settingData = data;
          let date = new Date().getTime();
          let storeDomain = localStorage.getItem(`store-domain`).split(`.`)[0];
          let domain = process.env.NODE_ENV === 'development' ? `.dcomcy.com` : '.dcomcy.com';

          // console.log(`storeDomain`, storeDomain);
          let time = new Date().getTime();
          if (this.nameTheme === `newchick`) {
            this.urlTheme = `https://${storeDomain}-fashion${domain}?nocahe=${time}`;
            this.domainCheck = `${storeDomain}-fashion`;
          } else {
            this.urlTheme = `https://${storeDomain}-default${domain}?nocahe=${time}`;
            this.domainCheck = `${storeDomain}-default`;
          }
          // this.urlTheme = `https://${this.$store.state.auth.accountInfo.storeDomain}?cache=nocache${date}`;
          // this.urlTheme = 'http://localhost:3002?v=sdfsdfsdf';
          this.config = data;
        }
      } catch (error) {
        console.log(error);
        //empty
      }
      this.isLoading = false;
      this.changeConfig();
    },
    changePageStore() {
      this.changeTab('section');
      // if (this.$el && this.selectPage === `home`) {
      let iframe = document.getElementById('srcPreview');
      if (!iframe || !iframe.contentWindow) {
        return;
      }
      let iframeWindow = iframe.contentWindow;
      let data = null;
      if (this.nameTheme === 'newchick') {
        let findCollection;
        let check = false;
        this.mainMenu[0].items.map(item1 => {
          if (item1 && item1.link && item1.link.includes('/collections/') && !check) {
            findCollection = {
              slug: item1.link.replace('/collections/', ''),
              id: item1.id,
              index: 1,
            };
            check = true;
          } else if (item1 && item1.children.length > 0 && !check) {
            item1.children.map(item2 => {
              if (item2 && item2.link && item2.link.includes('/collections/') && !check) {
                findCollection = {
                  slug: item2.link.replace('/collections/', ''),
                  id: item2.id,
                  index: 2,
                };
                check = true;
              } else if (item2 && item2.children && item2.children.length > 0 && !check) {
                item2.children.map(item3 => {
                  if (item3 && item3.link && item3.link.includes('/collections/') && !check) {
                    findCollection = {
                      slug: item3.link.replace('/collections/', ''),
                      id: item3.id,
                      index: 3,
                    };
                    check = true;
                  }
                });
              }
            });
          }
        });
        console.log('findCollection', this.selectPage);
        iframeWindow.postMessage(
          JSON.stringify({
            hiwebMessageEvent: 'event',
            block: 0,
            section: 'pageChange',
            data: this.selectPage,
            theme: this.domainCheck,
            collection: findCollection,
          }),
          '*',
        );
      } else {
        console.log('theme hydro');
        iframeWindow.postMessage(
          JSON.stringify({
            hiwebMessageEvent: 'event',
            block: 0,
            section: 'pageChange',
            theme: this.domainCheck,
            data: this.selectPage,
          }),
          '*',
        );
        // }
        // Send msg
        // iframeWindow.postMessage(
        //   JSON.stringify({
        //     hiwebMessageEvent: 'event',
        //     block: 0,
        //     section: 'pageChange',
        //     data: this.selectPage,
        //   }),
        //   '*',
        // );
      }
      // if (this.selectPage !== `product`) {
      //   let iframe = this.$el.querySelector('iframe');
      //   let iframeWindow = iframe.contentWindow;
      //   if (this.selectPage === 'product') {
      //     this.selectionSection(`product-separate`);
      //     iframeWindow.postMessage(
      //       JSON.stringify({
      //         hiwebMessageEvent: 'event',
      //         block: 0,
      //         section: 'pageChange',
      //         data: this.selectPage,
      //       }),
      //       '*',
      //     );
      //   }
      // }
    },
    async loadProduct() {
      let params = {
        page: 1,
        limit: 1,
      };
      try {
        let data = await productApi.get(params);
        this.product = data.data.products[0];
      } catch (error) {
        console.log(error);
      }
    },
    async loadCollection() {
      let params = {
        page: 1,
        limit: 1,
      };
      try {
        let data = await collectionApi.get(params);
        this.collection = data.data.collections[0];
      } catch (error) {
        console.log(error);
      }
    },
    changeConfig(section = '', i, headimage) {
      try {
        if (this.$el) {
          let iframe = document.getElementById('srcPreview');
          if (!iframe || !iframe.contentWindow) {
            return;
          }
          let iframeWindow = iframe.contentWindow;
          this.settingData.pages['fixed'] = this.pageFixedSettings;
          if (this.nameTheme === 'newchick') {
            if (!this.settingData.pages['fixed'].header.settings.image_topbar_mobile) {
              this.settingData.pages['fixed'].header.settings.image_topbar_mobile =
                'https://imgaz1.chiccdn.com/os/202108/20210802034837_374.jpg';
            }
          }
          // delete this.settingData.pages['homepage']['product-review-ieiw2f'];
          //
          // this.settingData.settings = this.settings;
          this.auth();
          // Send msg
          let payload = {
            hiwebMessageEvent: 'event',
            section: section,
            block: 0,
            scrollToId: this.scrollToId,
            data: this.settingData,
            theme: this.domainCheck,
            isScroll: this.isScroll,
          };
          if (i >= 0) {
            payload.index = i;
          }
          if (headimage) {
            payload.headimage = headimage;
          }
          iframeWindow.postMessage(JSON.stringify(payload), '*');
          this.sendMessage(payload);
          this.isScroll = false;
        }
      } catch (error) {
        console.log('change a ', error);
      }
    },
    sendMessage(data) {
      const event = new CustomEvent('sendData', { product: data });
      window.dispatchEvent(event);
    },
    load() {
      setTimeout(() => {
        this.changeConfig('', 0);
      }, 4000);
      setTimeout(() => {
        this.loaded = true;
      }, 5000);
    },
    changeTab(tab) {
      this.tab = tab;
    },
    changeScreen(device) {
      this.deviceView = device;
      if (device == 'mobile') {
        this.$refs.iframeTheme.style.width = '375px';
        this.$refs.iframeTheme.style.height = '667px';
      } else {
        this.$refs.iframeTheme.style.width = '100%';
        this.$refs.iframeTheme.style.height = '100%';
      }
      this.changeConfig();
    },
    backSection() {
      this.isSelectSection = false;
    },
    getImageUpLoadLogoDesktop(images) {
      this.pageFixedSettings.header.block[1].config[0].value = images[0] ? images[0].fullPath : this.logoDefault;
      this.changeConfig('header', this.pageFixedSettings);
    },
    getImageUpLoadMobile(images) {
      this.pageFixedSettings.header.block[1].config[1].value = images[0] ? images[0].fullPath : this.logoDefault;
      this.changeConfig('header', this.pageFixedSettings);
    },
    getImageUpLoadLogoFooter(images) {
      this.pageFixedSettings.footer.block[0].config[3].value = images[0] ? images[0].fullPath : this.logoDefault;
      this.changeConfig('header', this.pageFixedSettings);
    },
    selectionSection(type, setting) {
      this.isSelectSection = true;
      this.selectSection = type;
      if (type !== 'footer' && type !== 'header') {
        this.configSection = setting;
        this.scrollToId = setting && setting.key ? setting.key : 'product-separate';
      } else {
        this.scrollToId = type;
      }
      if (type === 'product-separate') {
        this.selectPage = 'product';
        this.changePageStore();
      }
      if (type === 'cart-setting' || type === 'cart-goal-setting') {
        this.selectPage = 'cart';
        this.changePageStore();
      }
      if (type === 'thankpage-separate') {
        this.selectPage = 'order';
        this.changePageStore();
      }
      if (type === 'colors-setting') {
        this.selectPage = 'product';
        this.changePageStore();
      }
      if (type === 'typography-setting') {
        this.selectPage = 'product';
        this.changePageStore();
      }
      if (type === 'category') {
        this.selectPage = 'category';
        this.changePageStore();
      }
      this.isScroll = true;
      this.changeConfig();
    },
  },
};
