<template>
  <div class="toolbar-page">
    <v-app-bar app fixed color="white">
      <v-container class="d-flex align-center">
        <button class="d-md-none mr-4 grey--text" icon @click.stop="drawer = !drawer">
          <i v-if="!drawer" class="ri-arrow-right-circle-line ri-xl"></i>
          <i v-else class="ri-arrow-left-circle-line ri-xl"></i>
        </button>
        <router-link to="/admin" class="logo">
          <img src="/admin/img/logo.png" alt="DCOMCY" />
        </router-link>
        <v-spacer></v-spacer>
        <v-btn icon @click="onFullScreen">
          <v-icon>mdi-fullscreen</v-icon>
        </v-btn>
        <Notification />
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-badge bordered top color="success" dot offset-x="8" offset-y="8">
              <v-avatar v-on="on" :size="32" class="pointer">
                <v-img v-if="user && user.profilePicture" :src="imageHelpers.url(user.profilePicture)" alt="Avatar" />
                <v-img src="/admin/img/avatar.jpg" />
              </v-avatar>
            </v-badge>
          </template>
          <v-card class="pa-0" outlined>
            <div
              v-if="user"
              class="d-flex align-center pa-3 primary white--text"
              style="gap: 12px; margin-inline: -1px;"
            >
              <v-avatar :size="40">
                <v-img v-if="user && user.profilePicture" :src="imageHelpers.url(user.profilePicture)" alt="Avatar" />
                <v-img src="/admin/img/avatar.jpg" />
              </v-avatar>
              <div class="flex pr-6">
                <h4>{{ fullName }}</h4>
                <p class="mb-0" style="font-size: 14px; opacity: 0.8;">{{ user.email }}</p>
              </div>
            </div>
            <v-list class="py-0">
              <v-list-item v-for="(action, index) in itemsLink" :key="index" @click="choseItem(action)" link>
                <i :class="`${action.icon} ri-lg ${index === itemsLink.length - 1 ? 'error--text' : ''}`"></i>
                <v-list-item-title
                  class="pl-4"
                  :class="index === itemsLink.length - 1 ? 'error--text' : ''"
                  style="font-size: 14px;"
                  >{{ action.title }}</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-container>

      <template v-if="!isMobile" #extension>
        <div class="container">
          <v-list class="navigation-list pa-0">
            <template v-for="(item, index) in items">
              <v-menu v-if="isChildrens(item)" :key="item.title" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on" link>
                    <i :class="`${item.icon} ri-lg`"></i>
                    <v-list-item-title class="font-weight-medium" style="font-size: 14px;">{{
                      item.title
                    }}</v-list-item-title>
                    <v-icon style="opacity: 0.7;" :size="18">{{
                      attrs['aria-expanded'] === 'true' ? 'mdi-chevron-up' : 'mdi-chevron-down'
                    }}</v-icon>
                  </v-list-item>
                </template>
                <v-list class="pa-0" dense>
                  <template v-for="(child, subIndex) in item.children">
                    <v-list-item
                      v-if="
                        (child.name === 'website.product-personalized' && !isDropship) ||
                          child.name !== 'website.product-personalized'
                      "
                      :key="subIndex"
                      :class="currentPage === child.name && 'v-list-item--active'"
                      @click="
                        utilities.checkPermisson(child.role, user)
                          ? changePage({
                              name: child.name,
                              params: child.params,
                              child: child.query,
                            })
                          : $router.push({ name: 'no-access' })
                      "
                    >
                      <i class="ri-circle-line ri-sm"></i>
                      <v-list-item-title class="ml-4" style="font-size: 14px;">
                        {{ $t(child.title) }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-list>
              </v-menu>
              <v-list-item
                v-else
                :key="index"
                @click="onClickNavigationItem(item)"
                link
                :class="currentPage === item.name && 'v-list-item--active'"
              >
                <i :class="`${item.icon} ri-lg`"></i>
                <v-list-item-title class="font-weight-medium" style="font-size: 14px;">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </div>
      </template>
    </v-app-bar>
    <v-navigation-drawer v-if="isMobile" app fixed v-model="drawer" color="white" width="50%" height="100%">
      <template #prepend>
        <div class="px-4 pt-4">
          <router-link to="/admin" class="logo">
            <img src="/admin/img/logo.png" alt="DCOMCY" />
          </router-link>
        </div>
      </template>
      <v-list dense>
        <template v-for="(item, index) in items">
          <v-list-group v-if="isChildrens(item)" :key="item.title" link>
            <template v-slot:activator>
              <v-icon :size="20" @click.stop="$router.push({ name: item.children[0].name })">{{ item.icon }}</v-icon>
              <v-list-item-title class="text-sm ml-3">
                {{ $t(item.title) }}
              </v-list-item-title>
            </template>
            <template v-if="item.title === 'Online Store'" v-slot:appendIcon>
              <button @click="goToStore"></button>
            </template>
            <template v-for="(child, subIndex) in item.children">
              <v-list-item
                v-if="
                  (child.name === 'website.product-personalized' && !isDropship) ||
                    child.name !== 'website.product-personalized'
                "
                :key="subIndex"
                link
                :class="currentPage === child.name && 'v-list-item--active'"
                @click="
                  utilities.checkPermisson(child.role, user)
                    ? changePage({
                        name: child.name,
                        params: child.params,
                        child: child.query,
                      })
                    : $router.push({ name: 'no-access' })
                "
              >
                <v-list-item-icon class="mr-2 pl-1 d-flex align-center">
                  <i class="ri-circle-line ri-sm"></i>
                </v-list-item-icon>
                <v-list-item-title class="text-sm">
                  {{ $t(child.title) }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list-group>
          <v-list-item
            v-else
            :key="index"
            @click="onClickNavigationItem(item)"
            link
            :class="currentPage === item.name && 'v-list-item--active'"
          >
            <v-icon :size="20">{{ item.icon }}</v-icon>
            <v-list-item-title class="text-sm ml-3">{{ item.title }}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-dialog
      v-if="proxiesList.length === 0 && showWarning && loadedProxy && enableRef"
      v-model="notifyProxy"
      :width="480"
    >
      <v-card>
        <v-card-title>Notify</v-card-title>
        <v-card-text>
          Your store hasn't been configured with a payment gateway, please contact the administrator to set it up before
          publishing(run ads)!!!
        </v-card-text>
        <v-card-actions class="justify-end pr-4 pb-4">
          <v-btn @click="notifyProxy = false">CLOSE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { payoutService } from '@/apis/payout';
import currency from '@/helpers/currency';
import MENU from '@/const/mockData';
import { mapGetters, mapState } from 'vuex';
import { authService } from '@/apis/auth.s';
import { apiConfig } from '@/apis/config.js';
import { imageFake } from '@/const/image';
import imageHelpers from '@/helpers/image';
import verifyMixin from '@/mixins/verify';
import utilities from '@/helpers/utilities';
import event from '@/plugins/event-bus';
import { apiProxy } from '@/apis/proxy';
import STORAGE_NAME from '@/const/storage';
import { domainService } from '@/apis/domain.s';
import Notification from './notification';

var timer;
var loadProxy;
export default {
  mixins: [verifyMixin],
  components: {
    Notification,
  },
  data() {
    return {
      isShowStep: false,
      utilities,
      indexItem: '',
      drawer: true,
      mini: true,
      items: [],
      showWarning: true,
      notifyProxy: true,
      imageFake,
      imageHelpers,
      detailStep: {},
      proxiesList: [],
      loadedProxy: false,
      step: {},
      enableRef: false,
      isVip: false,
      itemsLink: [
        { title: 'Profile', router: 'website.profile', params: {}, icon: 'ri-account-circle-line' },
        { title: 'Select another store', router: 'auth', params: { authPage: 'shop' }, icon: 'ri-store-3-line' },
        { title: 'Open your site', router: '', params: {}, icon: 'ri-external-link-line' },
        { title: 'Sign out', router: 'logout', params: {}, icon: 'ri-shut-down-line' },
      ],
      paymentChoice: null,
      showImportFunc: false,
      whiteEmailList: ['support@dcomcy.com', 'admin@dcomcy.com', 'nguyenduy@dcomcy.com', 'account@ak11.work'],
    };
  },
  async created() {
    // this.getStep();
    // event.$on('getStepStatus', message => {
    //   this.getStep();
    // });
    this.checkEnableRef();
    this.updateProxyList('this.$store.state.auth?.user');
  },
  beforeMount() {},
  mounted() {
    this.updateBalance();
    this.paymentChoice = this.pagePreference?.paymentChoice?.type || 'seller';
    if (this.pagePreference && this.pagePreference.businessType === 'dropship') {
      this.isDrop = true;
    }
    this.showImportFunc = this.paymentChoice === 'seller' || (this.paymentChoice === 'latte' && !this.isDrop);
    this.loadMenu();
  },
  methods: {
    loadMenu() {
      let currentUser = this.$store.state.auth?.user || null;
      if (!currentUser) {
        currentUser = JSON.parse(localStorage.getItem('dcomcy-user'));
      }
      if (currentUser?.email?.indexOf('@lattehub.com') >= 0) {
        this.items = MENU.menu || [];
      } else {
        let menus = MENU.limitMenu || [];
        if (this.whiteEmailList.indexOf(currentUser?.email) >= 0) {
          if (!menus.find(item => item.title == 'Online Store')?.children.find(item => item.title == 'Themes')) {
            menus
              .find(item => item.title == 'Online Store')
              .children.push({
                title: 'Themes',
                name: 'website.themes',
                role: 'themes',
              });
          }
        }
        this.items = menus;
      }

      if (this.pagePreference) {
        // if (this.pagePreference?.paymentChoice?.type === 'latte') {
        //   let item = this.items.find(item => item.title === 'Payout');
        //   if (!item) {
        //     this.items.push({
        //       title: 'Payout',
        //       icon: 'ri-bank-card-line',
        //       name: 'website.settings.payout',
        //       children: [],
        //       type: 'only',
        //       role: 'settings',
        //     });
        //   }
        // }

        if (this.pagePreference?.paymentChoice?.type === 'seller') {
          let item = this.items
            .find(item => item.title == 'Online Store')
            .children.find(item => item.title == 'Themes');
          if (!item)
            this.items
              .find(item => item.title == 'Online Store')
              .children.push({
                title: 'Themes',
                name: 'website.themes',
                role: 'themes',
              });
        }
      }
    },
    async loadBalance() {
      try {
        let res = await payoutService.getBalance();
        this.$store.commit('pagePreference/setBalance', res.data?.availableToPayout || 0);
      } catch (err) {}
    },
    async updateBalance() {
      if (timer) clearTimeout(timer);
      await this.loadBalance();
      timer = setTimeout(() => {
        this.updateBalance();
      }, 900000);
    },
    async loadProxy() {
      const storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
      const { data } = await apiProxy.get({
        limit: 10,
        page: 1,
        storeId: storeId,
      });
      this.proxiesList = data?.proxies;
      this.loadedProxy = true;
    },
    async updateProxyList() {
      if (loadProxy) clearTimeout(loadProxy);
      this.showWarning = true;
      this.notifyProxy = true;
      await this.loadProxy();
      if (this.proxiesList.length === 0) {
        loadProxy = setTimeout(() => {
          this.updateProxyList();
        }, 120000);
      }
    },
    async getStep() {
      try {
        let res = await apiConfig.getStatus();
        this.detailStep = res.data.currentConfig;
        if (this.detailStep.field === `domainConfigured`) {
          this.detailStep.description = this.detailStep.description.replace(
            `{domain}`,
            localStorage.getItem(`store-domain`),
          );
        }
        this.step = res.data;
        this.isShowStep = true;
      } catch (error) {}
    },
    clickStep() {
      this.$router.push({ path: `/${this.detailStep.path}` });
      this.isShowStep = false;
    },
    async checkEnableRef() {
      try {
        const checkEnableRef = await domainService.checkEnableRef();
        if (checkEnableRef) {
          this.enableRef = checkEnableRef.data[0].enableRef;
          this.isVip = checkEnableRef.data[0].isVip;
        }
      } catch (error) {
        this.errors = error;
      }
    },
    async skipStep() {
      try {
        if (this.detailStep.button !== `Back to Home`) {
          let res = await apiConfig.skipStep();
          this.detailStep = res.data.currentConfig;
          this.step = res.data;
        } else {
          this.isShowStep = false;
        }

        // await apiConfig.getStatus();
      } catch (error) {
        console.log('error', 'error', error);
      }
    },
    showStep() {
      this.isShowStep = !this.isShowStep;
    },
    openChildren(i) {
      console.log(1);
    },
    changePage(name) {
      this.$router.push(name);
      this.auth();
    },
    goToStore() {
      window.open('https://' + this.$store.state.auth.accountInfo.rDomain);
    },
    choseItem(item) {
      if (item.title === 'Logout') {
        try {
          authService.logout();
          localStorage.clear();
          window.location.href = this.$urlAuth;
        } catch (error) {
          console.log(error.response);
        }
      } else if (item.title === 'Open your site') {
        window.open('https://' + this.$store.state.auth.accountInfo.rDomain);
      } else {
        this.$router.push({ name: item.router, params: item.params });
      }
      // if (item.title === 'Profile') {
      //   this.$router.push({ name: item.router, params: item.params });
      // }
      // if (item.title === 'Select another shop') {
      //   window.location.href = this.$urlAuth + 'shop';
      // }
    },
    onFullScreen() {
      const doc = window.document;
      const docEl = doc.documentElement;

      const requestFullScreen =
        docEl.requestFullscreen ||
        docEl.mozRequestFullScreen ||
        docEl.webkitRequestFullScreen ||
        docEl.msRequestFullscreen;
      const cancelFullScreen =
        doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

      if (
        !doc.fullscreenElement &&
        !doc.mozFullScreenElement &&
        !doc.webkitFullscreenElement &&
        !doc.msFullscreenElement
      ) {
        requestFullScreen.call(docEl);
      } else {
        cancelFullScreen.call(doc);
      }
    },
    isChildrens(item) {
      return (
        item.children &&
        item.children.length > 0 &&
        (!item.role || (item.role && utilities.checkPermisson(item.role, this.user)))
      );
    },
    onClickNavigationItem(item) {
      if (this.utilities.checkPermisson(item.role, this.user))
        this.changePage({ name: item.name, query: item.query, params: item.params });
      else this.$router.push({ name: 'no-access' });
    },
  },
  watch: {
    '$store.state.pagePreference.preference'() {
      let paymentChoice = this.pagePreference?.paymentChoice?.type || 'seller';
      this.showImportFunc = paymentChoice === 'seller' || (paymentChoice === 'latte' && !this.isDrop);
    },
    user() {
      let currentUser = this.$store.state.auth?.user || null;
      if (!currentUser) {
        currentUser = JSON.parse(localStorage.getItem('dcomcy-user'));
      }
      if (currentUser?.email?.indexOf('@lattehub.com') >= 0) {
        this.items = MENU.menu;
      } else {
        let menus = MENU.limitMenu;
        if (this.whiteEmailList.indexOf(currentUser?.email) >= 0) {
          if (!menus.find(item => item.title == 'Online Store')?.children.find(item => item.title == 'Themes')) {
            menus
              .find(item => item.title == 'Online Store')
              .children.push({
                title: 'Themes',
                name: 'website.themes',
                role: 'themes',
              });
          }
        }
        this.items = menus;
      }
      // if (this.pagePreference.paymentChoice.type === 'latte') {
      //   let item = this.items.find(item => item.title === 'Payout');
      //   if (!item) {
      //     this.items.push({
      //       title: 'Payout',
      //       icon: 'ri-bank-card-line',
      //       name: 'website.settings.payout',
      //       children: [],
      //       type: 'only',
      //       role: 'settings',
      //     });
      //   }
      // }

      if (this.pagePreference.paymentChoice.type === 'seller') {
        let item = this.items.find(item => item.title == 'Online Store').children.find(item => item.title == 'Themes');
        if (!item)
          this.items
            .find(item => item.title == 'Online Store')
            .children.push({
              title: 'Themes',
              name: 'website.themes',
              role: 'themes',
            });
      }
    },
  },
  computed: {
    balance() {
      let val = this.$store.state.pagePreference.balance || 0;
      return currency.priceFormat(val);
    },
    isDropship() {
      return this.pagePreference && this.pagePreference.businessType === 'dropship';
    },
    ...mapState({
      pagePreference: state => state.pagePreference.preference,
    }),
    ...mapGetters(['user']),
    fullName() {
      if (!this.user?.firstName && !this.user?.lastName) return 'User';
      return this.user.firstName + ' ' + this.user.lastName || '';
    },
    currentPage: function() {
      // console.log(this.$route);
      return this.$route.name;
    },
    activeGroup: function() {
      // dùng để filter $route.name nào  === với name trong mockData thì toggle nó xuống
      let group = this.items.find(
        item =>
          item.name == this.currentPage ||
          (item.children &&
            item.children.find(item => item.name == this.currentPage || this.currentPage.includes(item.name))),
      );
      if (group?.title == 'Online Store') {
        group.status = true;
      }
      if (
        group?.name == 'website.discount.code' ||
        group?.name == 'website.orders' ||
        group?.name == 'website.analytics' ||
        group?.name == 'website.products'
      )
        group.status = true;
      return group;
    },
    isMobile() {
      return window?.innerWidth < 960;
    },
  },
};
</script>
<style lang="scss" scoped>
.close-step {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.logo {
  display: block;
  width: 150px;
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}
::v-deep .v-toolbar {
  box-shadow: unset !important;
}
::v-deep .v-toolbar__extension {
  padding-block: 0;
  border-block: solid 1px var(--v-border-base) !important;

  .navigation-list {
    display: flex;
    align-items: stretch;
    background-color: transparent;
    height: 100%;
    box-shadow: unset;
    .v-list-item {
      display: flex;
      align-items: center;
      gap: 6px;
      padding-right: 8px;
      flex: unset;
      &:hover::before {
        background-color: transparent;
      }
      .v-list-item__title {
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0.8px;
      }
    }
    .v-list-item--active {
      color: var(--v-primary-base);
      &:before {
        background-color: transparent;
      }
    }
  }
}
</style>
