<template>
  <div style="width: 100%; height: 100%">
    <div style="width: 100%; height: 100%" v-if="isLoading" class="d-flex justify-center align-center">
      <page-loader />
    </div>
    <div v-else class="page-editor d-flex">
      <div v-if="!isAddSection" class="d-flex" style="height: calc(100%)">
        <div class="sidebar" v-if="!isSelectSection">
          <div class="logo-desc d-flex align-center pointer" @click="$router.push({ name: 'website.themes' })">
            <img :src="``" />
            <h4 class="mx-10">{{ nameTheme === 'hydro' ? 'DEFAULT' : 'FASHION' }}</h4>
          </div>
          <div class="d-flex header">
            <div :class="'btn-theme ' + (tab === 'section' ? 'active' : '')" @click="changeTab('section')">
              Sections
              <v-tooltip bottom color="white">
                <template v-slot:activator="{ on, attrs }">
                  <i class="ml-2 far fa-question-circle pointer" v-bind="attrs" v-on="on"></i>
                </template>
                <div>
                  <span></span>
                  <img :src="imageFake.SectionsHelp" alt="" srcset="" />
                </div>
              </v-tooltip>
            </div>
            <div
              @click="changeTab('setting')"
              :class="'btn-theme ' + (tab === 'setting' ? 'active' : '')"
              class="btn-theme"
            >
              Setting
            </div>
          </div>
          <div class="content-editor">
            <div v-if="tab === 'section'">
              <ItemSection
                :callbackSelection="selectionSection"
                title="Header"
                sub-title="Header Setting"
                type="header"
              />
              <div v-if="selectPage === 'home'" class="list-section pt-2">
                <draggable v-if="showFullSettings == true" v-model="sectionDrag" @change="change">
                  <transition-group>
                    <template v-for="(sections, index) in sectionDrag">
                      <ItemSection
                        v-if="sections.type !== 'test'"
                        :isAddSection="sections.type !== 'slider-product' ? true : false"
                        :key="index"
                        :position="index"
                        :callbackSelection="selectionSection"
                        :callbackAddSection="showSectionAdd"
                        :title="sections.type"
                        :type="sections.type"
                        :settings="sections.settings"
                        :dataSection="sections"
                        sub-title=""
                      />
                    </template>
                  </transition-group>
                </draggable>
              </div>
              <ItemSection
                v-if="showFullSettings == true"
                :callbackSelection="selectionSection"
                title="Footer"
                sub-title="Footer Setting"
                type="footer"
              />
            </div>
            <div v-if="tab === 'setting' && nameTheme === 'hydro'">
              <ItemSeparate
                v-if="settings && settings['colors'] && selectPage === 'home'"
                title="Colors"
                :callbackSelection="selectionSection"
                sub-title="Product Setting"
                :dataSection="settings['colors']"
                :settings="settings['colors']"
                type="colors-setting"
                section="colors-setting"
              />
              <ItemSeparate
                v-if="settings && settings['typography'] && selectPage === 'home'"
                title="Typography"
                :callbackSelection="selectionSection"
                sub-title="Typography"
                :dataSection="settings['typography']"
                :settings="settings['typography-setting']"
                type="typography-setting"
                section="typography-setting"
              />
              <ItemSeparate
                v-if="settings && settings['product'] && selectPage === 'product'"
                :callbackSelection="selectionSection"
                title="Product"
                :dataSection="settings['product']"
                :settings="settings['product']"
                sub-title="Product Setting"
                type="product-separate"
                section="product-separate"
              />
              <ItemSeparate
                v-if="settings && settings['checkout'] && selectPage === 'checkout'"
                :callbackSelection="selectionSection"
                title="Checkout"
                :dataSection="settings['checkout']"
                :settings="settings['checkout']"
                sub-title="Checkout Setting"
                type="checkout-setting"
                section="checkout-setting"
              />
              <ItemSeparate
                v-if="settings && settings['style'] && selectPage === 'home'"
                :callbackSelection="selectionSection"
                title="Style "
                :dataSection="settings['style']"
                :settings="settings['style']"
                sub-title="Style Setting"
                type="style-setting"
                section="style-setting"
              />
              <ItemSeparate
                v-if="settings && settings['cart'] && selectPage === 'cart'"
                :callbackSelection="selectionSection"
                title="Cart"
                :dataSection="settings['cart']"
                :settings="settings['cart']"
                sub-title="Cart Setting"
                type="cart-setting"
                section="cart-setting"
              />
              <ItemSeparate
                v-if="settings && settings['thankpage'] && selectPage === 'order'"
                :callbackSelection="selectionSection"
                title="Thank You"
                :dataSection="settings['thankpage']"
                :settings="settings['thankpage']"
                sub-title="Thankyou Setting"
                type="thankpage-separate"
                section="thankpage-separate"
              />
              <ItemSeparate
                v-if="settings && settings['element'] && selectPage === 'home'"
                :callbackSelection="selectionSection"
                title="Element"
                :dataSection="settings['element']"
                :settings="settings['element']"
                sub-title="Element Setting"
                type="element"
                section="element"
              />
              <ItemSeparate
                v-if="settings && settings['cart_goal'] && selectPage === 'cart'"
                :callbackSelection="selectionSection"
                title="Cart Goal"
                :dataSection="settings['cart_goal']"
                :settings="settings['cart_goal']"
                sub-title="Cart Goal  Setting"
                type="cart-goal-setting"
                section="cart-goal-setting"
              />
              <!-- <ItemSeparate
                v-if="settings && settings['element'] && selectPage === 'home'"
                :callbackSelection="selectionSection"
                title="Element"
                :dataSection="settings['element']"
                :settings="settings['element']"
                sub-title="Element Setting"
                type="element"
                section="element"
              /> -->
              <ItemSeparate
                v-if="settings && settings['currency'] && selectPage === 'home'"
                :callbackSelection="selectionSection"
                title="Currency"
                :dataSection="settings['currency']"
                :settings="settings['currency']"
                sub-title="Currency Setting"
                type="currency"
                section="currency"
              />
            </div>
            <div v-if="tab === 'setting' && nameTheme === 'newchick'">
              <ItemSeparate
                v-if="settings && settings['style'] && selectPage === 'home'"
                :callbackSelection="selectionSection"
                title="Style "
                :dataSection="settings['style']"
                :settings="settings['style']"
                sub-title="Style Setting"
                type="style-setting"
                section="style-setting"
              />
              <ItemSeparate
                v-if="settings && settings['colors'] && selectPage === 'home'"
                title="Colors"
                :callbackSelection="selectionSection"
                sub-title="Product Setting"
                :dataSection="settings['colors']"
                :settings="settings['colors']"
                type="colors-setting"
                section="colors-setting"
              />
              <ItemSeparate
                v-if="settings && settings['typography'] && selectPage === 'home'"
                title="Typography"
                :callbackSelection="selectionSection"
                sub-title="Typography"
                :dataSection="settings['typography']"
                :settings="settings['typography-setting']"
                type="typography-setting"
                section="typography-setting"
              />
              <ItemSeparate
                v-if="settings && settings['product'] && selectPage === 'product'"
                :callbackSelection="selectionSection"
                title="Product"
                :dataSection="settings['product']"
                :settings="settings['product']"
                sub-title="Product Setting"
                type="product-separate"
                section="product-separate"
              />
              <ItemSeparate
                v-if="settings && settings['category'] && selectPage === 'collections'"
                :callbackSelection="selectionSection"
                title="Collection"
                :dataSection="settings['category']"
                :settings="settings['category']"
                sub-title="collection Setting"
                type="category"
                section="category-separate"
              />
              <ItemSeparate
                v-if="settings && settings['checkout'] && selectPage === 'checkout'"
                :callbackSelection="selectionSection"
                title="Checkout"
                :dataSection="settings['checkout']"
                :settings="settings['checkout']"
                sub-title="Checkout Setting"
                type="checkout-setting"
                section="checkout-setting"
              />
              <ItemSeparate
                v-if="settings && settings['thankpage'] && selectPage === 'order'"
                :callbackSelection="selectionSection"
                title="Thank You"
                :dataSection="settings['thankpage']"
                :settings="settings['thankpage']"
                sub-title="Thankyou Setting"
                type="thankpage-separate"
                section="thankpage-separate"
              />
              <ItemSeparate
                v-if="settings && settings['cart'] && selectPage === 'cart'"
                :callbackSelection="selectionSection"
                title="Cart"
                :dataSection="settings['cart']"
                :settings="settings['cart']"
                sub-title="Cart Setting"
                type="cart-setting"
                section="cart-setting"
              />
              <ItemSeparate
                v-if="settings && settings['cart_goal'] && selectPage === 'cart'"
                :callbackSelection="selectionSection"
                title="Cart Goal"
                :dataSection="settings['cart_goal']"
                :settings="settings['cart_goal']"
                sub-title="Cart Goal  Setting"
                type="cart-goal-setting"
                section="cart-goal-setting"
              />
              <ItemSeparate
                v-if="settings && settings['popup'] && selectPage === 'home'"
                :callbackSelection="selectionSection"
                title="Popup"
                :dataSection="settings['popup']"
                :settings="settings['popup']"
                sub-title="Popup Setting"
                type="popup_settings"
                section="popup_settings"
              />
              <ItemSeparate
                v-if="settings && settings['element'] && selectPage === 'home'"
                :callbackSelection="selectionSection"
                title="Element"
                :dataSection="settings['element']"
                :settings="settings['element']"
                sub-title="Element Setting"
                type="element"
                section="element"
              />
              <ItemSeparate
                v-if="settings && settings['currency'] && selectPage === 'home'"
                :callbackSelection="selectionSection"
                title="Currency"
                :dataSection="settings['currency']"
                :settings="settings['currency']"
                sub-title="Currency Setting"
                type="currency"
                section="currency"
              />
            </div>
          </div>
        </div>
        <div class="sidebar" v-else>
          <div class="logo-desc d-flex align-center pointer" @click="backSection">
            <i class="fas fa-chevron-left mr-10"></i>
            <div class="fz-18" v-if="selectSection === 'thankpage-separate'">
              {{
                selectSection === 'thankpage-separate'
                  ? 'Thank You'
                  : (selectSection.charAt(0).toUpperCase() + selectSection.slice(1))
                      .replace('-', ' ')
                      .replace('_', ' ')
                      .replace('_', ' ')
              }}
            </div>
            <div class="fz-18" v-else-if="selectSection === 'product-separate'">
              Product
            </div>
            <div class="fz-18" v-else>
              {{
                (selectSection.charAt(0).toUpperCase() + selectSection.slice(1))
                  .replace('-', ' ')
                  .replace('_', ' ')
                  .replace('_', ' ')
              }}
            </div>
          </div>
          <v-divider></v-divider>
          <div>
            <div style="margin-top: 15px" class="pa-3" v-if="selectSection === 'header'">
              <EditHeader v-if="nameTheme === 'hydro'" />
              <edit-header-new-chic v-if="nameTheme === 'newchick'" />
              <v-divider class="my-3"></v-divider>
            </div>
          </div>
          <div style="margin-top: 15px" class="pa-3" v-if="selectSection !== 'footer' && selectSection !== 'header'">
            <ImageWithText
              :callbackRemoveContent="getCallbackRemoveConent"
              :callbackAddContent="getCallbackAddContent"
              :callbackRemoveSection="getCallbackRemoveSection"
              :configSection="configSection"
              v-if="selectSection === 'image_with_text'"
            />
            <div v-if="selectSection === 'product-widget'">
              <div>
                <label for="">SETTINGS</label>
              </div>
              <div>
                To customize and change settings of this section, please go to
                <a
                  @click="$router.push({ name: 'website.apps.widgets.list' })"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Product Widget</a
                >
              </div>
            </div>
            <Colors
              :nameTheme="nameTheme"
              :configSection="settings['colors']"
              v-if="selectSection === 'colors-setting'"
            />
            <Typography :configSection="settings['typography']" v-if="selectSection === 'typography-setting'" />
            <ProductSetting
              :configSection="settings['product']"
              v-if="selectSection === 'product-separate' && nameTheme === 'hydro'"
            ></ProductSetting>
            <style-setting :configSection="settings['style']" v-if="selectSection === 'style-setting'"></style-setting>

            <ElementSetting :configSection="settings['element']" v-if="selectSection === 'element'"></ElementSetting>
            <CurrencySetting
              :configSection="settings['currency']"
              v-if="selectSection === 'currency'"
            ></CurrencySetting>
            <ProductSettingNewChic
              :configSection="settings['product']"
              v-if="selectSection === 'product-separate' && nameTheme === 'newchick'"
            ></ProductSettingNewChic>
            <PopupSetting
              :configSection="settings['popup']"
              v-if="selectSection === 'popup_settings' && nameTheme === 'newchick'"
            />
            <CartPageSetting
              :configSection="settings['cart']"
              v-if="selectSection === 'cart-setting'"
            ></CartPageSetting>
            <Checkout
              :configSection="settings['checkout']"
              :nameTheme="nameTheme"
              v-if="selectSection === 'checkout-setting'"
            ></Checkout>
            <ThankpageSetting
              :configSection="settings['thankpage']"
              v-if="selectSection === 'thankpage-separate'"
            ></ThankpageSetting>
            <CartGoal
              :nameTheme="nameTheme"
              :configSection="settings['cart_goal']"
              v-if="selectSection === 'cart-goal-setting'"
            ></CartGoal>
            <Collection :configSection="settings['category']" v-if="selectSection === 'category'" />
            <ProductReview
              :callbackRemoveContent="getCallbackRemoveConent"
              :callbackAddContent="getCallbackAddContent"
              :callbackRemoveSection="getCallbackRemoveSection"
              :configSection="configSection"
              v-if="selectSection === 'product-review'"
            />
            <SummerStyles
              :callbackRemoveContent="getCallbackRemoveConent"
              :callbackAddContent="getCallbackAddContent"
              :callbackRemoveSection="getCallbackRemoveSection"
              :configSection="configSection"
              v-if="selectSection === 'style-collection'"
            />
            <FeatureCollection
              :callbackRemoveContent="getCallbackRemoveConent"
              :callbackAddContent="getCallbackAddContent"
              :callbackRemoveSection="getCallbackRemoveSection"
              :configSection="configSection"
              v-if="selectSection === 'featured-collection' && nameTheme === 'hydro'"
            />
            <FeatureCollectionNewChic
              :callbackRemoveContent="getCallbackRemoveConent"
              :callbackAddContent="getCallbackAddContent"
              :callbackRemoveSection="getCallbackRemoveSection"
              :configSection="configSection"
              v-if="selectSection === 'featured-collection' && nameTheme === 'newchick'"
            />
            <FeatureContent
              :callbackRemoveContent="getCallbackRemoveConent"
              :callbackAddContent="getCallbackAddContent"
              :callbackRemoveSection="getCallbackRemoveSection"
              :configSection="configSection"
              v-if="selectSection === 'feature-content'"
            />
            <SliderProduct
              :callbackRemoveContent="getCallbackRemoveConent"
              :callbackAddContent="getCallbackAddContent"
              :callbackRemoveSection="getCallbackRemoveSection"
              :configSection="configSection"
              v-if="selectSection === 'slider-product'"
            />
            <product-with-collection
              :callbackRemoveContent="getCallbackRemoveConent"
              :callbackAddContent="getCallbackAddContent"
              :callbackRemoveSection="getCallbackRemoveSection"
              :configSection="configSection"
              v-if="selectSection === 'product_with_collection'"
            />
            <HeadImage
              :callbackRemoveContent="getCallbackRemoveConent"
              :callbackAddContent="getCallbackAddContent"
              :callbackRemoveSection="getCallbackRemoveSection"
              :configSection="configSection"
              v-if="selectSection === 'head-image'"
            />
            <CollectionList
              :callbackRemoveContent="getCallbackRemoveConent"
              :callbackAddContent="getCallbackAddContent"
              :callbackRemoveSection="getCallbackRemoveSection"
              :configSection="configSection"
              v-if="selectSection === 'collection_list'"
            />
            <FlashDeal
              :callbackRemoveContent="getCallbackRemoveConent"
              :callbackAddContent="getCallbackAddContent"
              :callbackRemoveSection="getCallbackRemoveSection"
              :configSection="configSection"
              v-if="selectSection === 'flash_deal'"
            />
            <!-- <pre>{{ configSection }}</pre>
            <v-text-field v-model="configSection.settings['blocks'][0].content" @change="changeConfig"></v-text-field> -->
          </div>
          <div style="margin-top: 15px" class="pa-3" v-if="selectSection === 'footer'">
            <EditFooter v-if="nameTheme === 'hydro'" />
            <edit-footer-new-chic v-if="nameTheme === 'newchick'" />
          </div>
        </div>
      </div>
      <div v-else-if="isAddSection" class="d-flex" style="height: calc(100%)">
        <SectionType :nameTheme="nameTheme" :callBackSubmitSelectAddSection="getCallBackSubmitSelectAddSection" />
      </div>
      <div style="width: 100%; height: 100%">
        <div class="header d-flex">
          <v-row class="mx-6 d-flex justify-space-between align-center">
            <v-select
              v-model="selectPage"
              hide-details=""
              class="scroll-select-page"
              style="max-width: 250px"
              item-text="name"
              item-value="slug"
              :readonly="!isClick"
              @change="changePageStore"
              :items="[
                { name: 'Home page', slug: 'home' },
                { name: 'Product page', slug: 'product' },
                { name: 'Cart page', slug: 'cart' },
                { name: 'Collection page', slug: 'collections' },
                { name: 'Returns page', slug: 'returns' },
                { name: '404 page', slug: '*' },
                { name: 'Checkout', slug: 'checkout' },
                { name: 'Thank you page', slug: 'order' },
                { name: 'FAQ', slug: 'size-guide' },
                { name: 'Shipping & Delivery', slug: 'my-shipping-details' },
              ]"
            ></v-select>
            <v-btn-toggle v-model="deviceView">
              <v-btn small value="mobile" @click="changeScreen('mobile')">
                <i class="fas fa-mobile-alt"></i>
              </v-btn>

              <v-btn small value="desktop" @click="changeScreen('desktop')">
                <i class="fas fa-desktop"></i>
              </v-btn>
            </v-btn-toggle>
            <div class="d-flex align-center">
              <v-btn class="mr-3" color="white" @click="$router.push({ name: 'website.themes' })">Close</v-btn>
              <v-btn :loading="isLoadingSubmit" @click="submitConfig" color="primary">Save</v-btn>
            </div>
          </v-row>
        </div>

        <div class="view-theme">
          <!-- asdasd <input id="avatar" type="file" @change="handleUploadAvatar" /> -->
          <page-loader v-show="!loaded" />

          <!-- :src="'https://' + urlTheme" -->
          <!-- src="http://localhost:3000" -->
          <iframe
            id="srcPreview"
            @load="load"
            v-show="loaded"
            v-if="urlTheme"
            class="iframe"
            :src="urlTheme"
            ref="iframeTheme"
            style="box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 0 12px rgba(0, 0, 0, 0.1); border-top: none"
          />
        </div>
      </div>
    </div>
    <css />
  </div>
</template>

<script>
import verifyMixin from '@/mixins/verify';
import logic from './logicEditor';
import EditHeaderNewChic from './components/newchic/EditHeaderNewChic.vue';
import EditFooterNewChic from './components/newchic/EditFooterNewChic.vue';
import ProductWithCollection from './components/newchic/ProductWithCollection.vue';
import ProductSettingNewChic from './components/newchic/ProductSettingNewChic.vue';
import PopupSetting from './components/newchic/PopupSetting.vue';
import FlashDeal from './components/newchic/FlashDeal.vue';
import FeatureCollectionNewChic from './components/newchic/FeatureCollectionNewChic';
import CartGoal from './components/newchic/CartGoal';
import Collection from './components/newchic/Collection';
import StyleSetting from './components/newchic/StyleSetting.vue';

export default {
  components: {
    FlashDeal,
    PopupSetting,
    EditHeaderNewChic,
    EditFooterNewChic,
    ProductWithCollection,
    ProductSettingNewChic,
    FeatureCollectionNewChic,
    CartGoal,
    Collection,
    StyleSetting,
  },
  mixins: [verifyMixin, logic],
};
</script>
